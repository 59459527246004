import React from "react";
import { BrowserRouter as Router, Routes, Route, useRoutes } from "react-router-dom";
import Home from './pages/home.js';
import Dashboard from './pages/dashboard';
import Joinnow from "./pages/join-now.js";
import { WagmiConfig, createConfig, configureChains, mainnet } from 'wagmi'
import { bsc, bscTestnet} from 'viem/chains'
import { publicProvider } from 'wagmi/providers/public'
import { InjectedConnector } from 'wagmi/connectors/injected'
import { MetaMaskConnector } from 'wagmi/connectors/metaMask'
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect'
import { ToastContainer } from "react-toastify";
import { SnackbarProvider } from 'notistack';
import NewsArticle from "./pages/newsacticale.js";
import Cms from "./pages/cms"
const { chains, publicClient, webSocketPublicClient } = configureChains(
  [bsc,bscTestnet],
  [publicProvider()],
)
const config = createConfig({

  autoConnect: true,
  connectors: [


    new MetaMaskConnector({ chains ,
      shimDisconnect: true,
    }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: '681700e231a5aef269b7fe4adb34981a',
        version: '2',
      },
    }),
    new InjectedConnector({
      chains,
      options: {
        name: 'Injected',
        shimDisconnect: true,
      },
    }),
  ],
  publicClient,
  webSocketPublicClient,
})
const App = () => {
  const routes = useRoutes([
    { path: "/", element: <Home /> },
    { path: "dashboard", element: <Dashboard /> },
    { path: "join-now", element: <Joinnow /> },
    {path:'news/:id',element:<NewsArticle />},
    {path:'/:id',element:<Cms />}
  ]);
  return routes;
};

const AppWrapper = () => {
  return (
    <Router basename="/">
       <WagmiConfig config={config}>
       <SnackbarProvider />
      <App />
      </WagmiConfig>
    </Router>
  );
};

export default AppWrapper;