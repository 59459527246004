import { connection } from "./connection";
import { Multicall } from "ethereum-multicall";
import config from "../config/config";
import Abi from "../ABI/Abi.json";
import { bignumber } from "../helper/custommath";
import { formatNumber } from "../helper/custommath";
import moment from "moment";

export const getuserDetailsAction = async (data) => {
  try {
    console.log(data, "userssssssssssssss");
    var get = await connection();
    console.log(get, "getget");
    if (get && get.web3 && get.address && get.network == config.NetworkId) {
      let address = get.address;
      console.log(address, "address");
      let web3 = get.web3;
      const multicall = new Multicall({
        web3Instance: web3,
      });

      var contractCallContext = [
        {
          reference: "details",
          contractAddress: config.contractAddress,
          abi: Abi,
          calls: [
            {
              reference: "userprofile",
              methodName: "users",
              methodParameters: [address],
            },
            {
              reference: "tokenprice",
              methodName: "tokenPrice",
            },
            {
              reference: "poolIncome",
              methodName: "viewPoolRoi",
              methodParameters: [address],
            },
            {
              reference: "RoiIncome",
              methodName: "viewTeamRoi",
              methodParameters: [address],
            },
            {
              reference: "DailyRoi",
              methodName: "viewDailyRoi",
              methodParameters: [address],
            },
          ],
        },
      ];

      const results = await multicall.call(contractCallContext);
      var tokenPrice = await getFormatMulticall(results, "tokenprice", 0);
      tokenPrice = (await bignumber(tokenPrice[0])) / 1e6;
      console.log(tokenPrice, "tokenPricetokenPricetokenPrice");
      var users = await getFormatMulticall(results, "userprofile", 0);
      var poolIncome = await getFormatMulticall(results, "poolIncome", 2);
      var RoiIncome = await getFormatMulticall(results, "RoiIncome", 3);
      var dailyRoi = await getFormatMulticall(results, "DailyRoi", 4);
      console.log(dailyRoi,'dailyRoi');

      var isexist = users[0];
      var userid = await bignumber(users[1]);
      var parentid = await bignumber(users[2]);
      var investAmount = await bignumber(users[3]);
      investAmount = parseFloat(investAmount);
      var childcount = await bignumber(users[4]);
      var teamCount = await bignumber(users[5]);
      var teamInvestment = await bignumber(users[6]);
      teamInvestment = parseFloat(teamInvestment);

      var poolincome1 = await bignumber(poolIncome[0]);
      var poolincome2 = await bignumber(poolIncome[1]);

      var roiIncome1 = await bignumber(RoiIncome[0]);
      var roiIncome2 = await bignumber(RoiIncome[1]);


      var dailyroiIncome1 = await bignumber(dailyRoi[0]);
      var dailyroiIncome2 = await bignumber(dailyRoi[1]);

      var totalpoolincome = parseFloat(poolincome1) + parseFloat(poolincome2);

      
      var totalroiincome = parseFloat(roiIncome1) + parseFloat(roiIncome2);

      var totaldailyroiincome = parseFloat(dailyroiIncome1) + parseFloat(dailyroiIncome2);

      var earnedIncome =
        parseFloat(totalroiincome) + parseFloat(totalpoolincome)+parseFloat(totaldailyroiincome);
      var rank = await bignumber(users[7]);
      var lastClaimed = await bignumber(users[13]);
      console.log(lastClaimed, "lastClaimedlastClaimed");
      var claimDate = new Date(lastClaimed * 1000);
      claimDate = claimDate.setDate(claimDate.getDate() + 20);
      lastClaimed = moment(claimDate).format("DD-MM-YYYY hh:mm");

      if (parseFloat(totalpoolincome) + parseFloat(totalroiincome) == 0) {
        lastClaimed = "-";
      }
      if (claimDate < new Date()) {
        lastClaimed = "-";
      }

      // var claimamount= await bignumber(claimdetails[0]);

      console.log(await formatNumber(totaldailyroiincome / 1e18, 5),'totaldailyroiincome'
      );
      return {
        status: true,
        isexist: isexist,
        userid: userid,
        parentid: parentid,
        childcount: childcount,
        investAmount: await formatNumber(investAmount / 1e18, 5),
        earnedIncome: await formatNumber(earnedIncome / 1e18, 5),
        poolincome: await formatNumber(totalpoolincome / 1e18, 5),
        roiIncome: await formatNumber(totalroiincome / 1e18, 5),
        rank: rank,
        lastClaimed,
        teamInvestment: await formatNumber(teamInvestment / 1e18, 5),
        teamCount: teamCount,
        totaldailyroiincome: await formatNumber(totaldailyroiincome / 1e18, 5),
      }; 
    } else {
      return {
        status: false,
        isexist: false,
        userid: 0,
        parentid: 0,
        childcount: 0,
        investAmount: 0,
        // claimamount:0,
        poolincome: 0,
        roiIncome: 0,
        rank: rank,
        lastClaimed: "-",
        teamInvestment: "0",
        teamCount: 0,
      };
    }
  } catch (err) {
    console.log(err, "eeeeerere");
    return {
      status: false,

      isexist: 0,
      userid: 0,
      parentid: 0,
      dailyearned: 0,
      planamount: 0,
      earnedamount: 0,
      referearn: 0,
      referal: 0,
      lastClaimed: "-",
      teamInvestment: "0",
      teamCount: 0,
    };
  }
};
export function getFormatMulticall(results, name, pos) {
  try {
    var index =
      results &&
      results.results &&
      results.results.details &&
      results.results.details.callsReturnContext &&
      results.results.details.callsReturnContext.findIndex(
        (val) => val.reference == name
      );
    var returnVal =
      results.results.details.callsReturnContext[index] &&
      results.results.details.callsReturnContext[index].returnValues
        ? results.results.details.callsReturnContext[index].returnValues
        : "";
    return returnVal;
  } catch (err) {
    console.log(err, "==+++++++");
    return "";
  }
}
