// import package
import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import {getRecentTransaction} from "../API/UserActions"
import { useSelector } from "react-redux";
import moment from "moment";
import config from '../config/config'
const columns = [
  {
      name: 'Date',
      selector: 'date',
      sortable: false,
  },
  
  {
      name: 'Transaction Ref.',
      selector: 'txhash',
      sortable: false,
  },
  {
    name: 'Amount',
    selector: 'amount',
    sortable: false,
},
  {
      name: 'Status',
      selector: 'status',
      sortable: false,
  },
];

const Staking = () => {
  const walletConnection = useSelector((state) => state.walletConnection);
    const [data, setData] = useState([])
    const [loader, setLoader] = useState(true)
    const [record, setRecord] = useState({
      'data': [],
      'count': 0
  })
  const [filter, setFilter] = useState({
    'address':'',
    'type': 'staking',
    'search': '',
    'page': 1,
    'limit': 5
})
    // funtion
    const fetchHistory = async (reqQuery) => {
        try {
            const { success, loading, result,count } = await getRecentTransaction(reqQuery);
            setLoader(loading)
            console.log(success,result,'result.data')
            let resultArr = []
            if (success == 'success') {
                setData(result)
                
                result.map((item) => {
                    resultArr.push({
                        'date': moment(item.createdAt).format('DD-MM-YYYY'),
                        'type': item.type,
                        'txhash': <a href={config.txUrl+item.txhash} target="_blank"> {item.txhash}</a>,
                        'amount': <span>    <img src={require("../assets/images/favicon.png")} width="26px" height="24px" className="img-fluid me-2"/>{item.amount}</span>,
                        'status': item.status
                    })
                    

                })
                console.log(result.count,'resultArr')
                setRecord({
                    'data': resultArr,
                    count: count
                })
                // resultArr.push({
                //     'date':"28-08-2023",
                //     'type': 'staking',
                //     'txhash': <a href={config.txUrl+'bc1qam3fkm5lnrnvg8s9y77xhjyesgs7urd0m7zht4'} target="_blank"> {'bc1qam3fkm5lnrnvg8s9y77xhjyesgs7urd0m7zht4'}</a>,
                //     'amount': <span>    <img src={require("../assets/images/coin3.png")} width="26px" height="24px"/>2</span>,
                //     'status': 'success'
                // })
                // setRecord({
                //     'data': resultArr,
                //     count: '1'
                // })
            }
            
        } catch (err) { }
    }

    useEffect(() => {
      let filterData = { ...filter, ...{ 'address':walletConnection.address } }
        setFilter(filterData)
        fetchHistory(filterData)
   
    }, [walletConnection && walletConnection.address])
    const handlePageChange = page => {
      let filterData = { ...filter, ...{ 'page': page } }
      setFilter(filterData)
      fetchHistory(filterData)
  };

  const handlePerRowsChange = async (newPerPage, page) => {
      let filterData = { ...filter, ...{ 'page': page, 'limit': newPerPage } }
      setFilter(filterData)
     fetchHistory(filterData)
  };
    return (
        // <div class="table-responsive">
        // <table class="table  
        // table-borderless 
        // align-middle">
        //   <thead class="table-light"> 
        //     <tr>
        //       <th>S.No</th>
        //       <th>Date</th> 
        //       <th>Transaction Address</th>
        //       <th>Amount</th>
        //     </tr>
        //     </thead>
        //     <tbody class="table-group-divider">
        //       <tr >
        //         <td >1</td>
        //         <td>28-08-2023</td> 
        //         <td>bc1qam3fkm5lnrnvg8s9y77xhjyesgs7urd0m7zht4</td>
        //         <td>2247.12</td>
        //       </tr>
        //       <tr>
        //         <td >2</td>
        //         <td>28-08-2023</td> 
        //         <td>1MUVs3V8Y2LS6pbQruhc2C8JJw8YGhGrU3</td>
        //         <td> 15456.25</td>
        //       </tr>
        //       <tr >
        //         <td >3</td>
        //         <td>28-08-2023</td> 
        //         <td>bc1qam3fkm5lnrnvg8s9y77xhjyesgs7urd0m7zht4</td>
        //         <td>2247.12</td>
        //       </tr>
        //       <tr>
        //         <td >4</td>
        //         <td>28-08-2023</td> 
        //         <td>1MUVs3V8Y2LS6pbQruhc2C8JJw8YGhGrU3</td>
        //         <td> 15456.25</td>
        //       </tr>
        //       <tr >
        //         <td >5</td>
        //         <td>28-08-2023</td> 
        //         <td>bc1q4vtpcf5muml9vq4hdx69d6axy0gxfyh3szms2t</td>
        //         <td> 54123.00</td>
        //       </tr>
        //     </tbody>
        //     <tfoot>
              
        //     </tfoot>
        // </table>
        // </div>
        <div className='tabledeatils' >
        <DataTable
        className="historyCryptoTable"
        columns={columns}
        data={record.data}
        paginationTotalRows={record.count}
            paginationRowsPerPageOptions={[5,10,15,20,500]}
            noHeader
            paginationPerPage={5}
            // progressPending={loader}
            pagination
            paginationServer
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
        />
        </div>
    )
}

export default Staking;