import React from "react";  

const plus = (props) => (
  <svg    {...props} xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
  <circle cx="15" cy="15" r="15" fill="#FCD434"/>
  <path d="M19 14V16H11V14H19Z" fill="black"/>
  <path d="M14 11L16 11L16 19H14L14 11Z" fill="black"/>
</svg> 

);

const minus = (props) => (
<svg  {...props} xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
<circle cx="15" cy="15" r="15" fill="#FCD434"/>
<path d="M19 15V17H11V15H19Z" fill="black"/>
</svg>
);

const close = (props) => (
  <svg    {...props} xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
  <circle cx="15" cy="15" r="15" fill="#FCD434"/>
  <path d="M19 14V16H11V14H19Z" fill="black"/>
  <path d="M14 11L16 11L16 19H14L14 11Z" fill="black"/>
</svg> 
);

export { plus, minus, close };
