import {
    WALLET_CONNECT,
} from '../constant';


export const setWallet = details => {
  
    return {
        type: WALLET_CONNECT,
        payload: details
    };
};
