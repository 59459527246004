import React, { useEffect, useRef, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import Countdown, { zeroPad } from "react-countdown";
import { Link } from "react-router-dom";
import { event } from "jquery";
import AOS from "aos";
import "aos/dist/aos.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getAllFaqAction, getAllLaunchPadAction, getAllNewsUpdates, getContact, getPresaleDate, getTbxchart, getUserchart, savemail } from '../API/UserActions'
import Web3 from 'web3';
import config from '../config/config'
import Abi from '../ABI/Abi.json'
import moment from "moment";
import Chart from 'react-apexcharts';
import { isEmpty } from "../helper/isEmpty";
import { toastAlert } from "../helper/toastAlert";
import ReactHtmlParser from 'react-html-parser';
import fileDownload from 'js-file-download'
import axios from "axios";
import {formatNumber} from '../helper/custommath';
import { bignumber, multiply, divide, add } from 'mathjs'
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Home() {
  const [presaledata, setpresaledata] = useState({});
  const [list1, setlist1] = useState([])
  const [list2, setlist2] = useState([])
  const [tbxchart, settbxchart] = useState({});
  const [tbxPrice, settbxPrice] = useState('')
  const [userchart, setuserchart] = useState({});
  const [showerror, setshowerror] = useState({});
  const [regbtn, setregbtn] = useState(false);
  const [mail, setmail] = useState("");
  const [news, setNews] = useState([]);
  const [launchpad, setLaunch] = useState([]);
  const [faqList, setfaq] = useState([])

  const aRef = useRef(null)
  useEffect(() => {
    AOS.init();
  }, []);

  async function getpresale() {
    let { result, success } = await getPresaleDate()
    if (success == true) {
      setpresaledata(result)
    }
  }
  async function getpricechart() {
    let { result, success } = await getTbxchart();
    console.log(result, 'result')
    settbxchart(result)

  }
  async function getchart() {
    let { result, success } = await getUserchart();
    console.log(result, "result222");
    setuserchart(result);
  }
  async function getpackage() {
    try {
      var web3 = new Web3(config.netWorkUrl);
      var contractAddress = config.contractAddress
      var Contract = new web3.eth.Contract(Abi, contractAddress);
      var tbxprice = await Contract.methods.tokenPrice().call()
      console.log(tbxprice, 'tbxprice')
      tbxprice = tbxprice / 1e6
      settbxPrice(tbxprice)
      var arr1 = []; var arr2 = []
      for (var i = 1; i <= 3; i++) {
        var obj1 = {}
        var data = await Contract.methods.PACKAGE_PRICE(i).call();

        var pkgprice = bignumber(data);
        var decimal = bignumber(1e18);
        var price = divide(pkgprice, decimal);
        price = price.toString();

        var tbxrate = bignumber(tbxprice);
        var tokenVal = divide(price, tbxrate);
        tokenVal =tokenVal.toString()



        obj1.packagenum = i;
        console.log(data / 1e18,'data / 1e18')
        obj1.packageprice = await formatNumber (price,4);
        obj1.usdtprice = await formatNumber(tokenVal,4);
        arr1.push(obj1)
      }
      setlist1(arr1)
      for (var i = 4; i <= 6; i++) {
        
        var obj2 = {}
        var data2 = await Contract.methods.PACKAGE_PRICE(i).call();


        var pkgpricee = bignumber(data2);
        var decimall = bignumber(1e18);
        var pricee = divide(pkgpricee, decimall);
        pricee = pricee.toString();

        var tbxratee = bignumber(tbxprice);
        var tokenVall = divide(pricee, tbxratee);
        tokenVall =tokenVall.toString()


        obj2.packagenum = i;
        obj2.packageprice = await formatNumber (pricee,4);
        obj2.usdtprice = await formatNumber(tokenVall,4);
        arr2.push(obj2)
      }
      setlist2(arr2)
      console.log(arr1, arr2, 'arr1arr1arr1arr1')
    }
    catch (err) {
      console.log(err, 'eeeeeeee')
    }
  }
  const handleMail = (e) => {
    console.log(e.target.value, "vlll");
    setmail(e.target.value);
  };
  const saveEmail = async () => {
    try {
      var chkaddr = /^([\w-\.]+@([\w-]+\.)+[\w-]{2,4})?$/.test(mail);
      if (isEmpty(mail)) {
        setshowerror({ emailerr: "Mail Id is required" });
        setregbtn(false);
        return;
      }
      else if (!chkaddr) {
        setshowerror({ emailerr: "Please enter valid mail" })
        setregbtn(false);
        return;
      }
      var savedata = {
        mail: mail
      }
      let { success, message } = await savemail(savedata)
      if (success == true) {
        setshowerror({ emailerr: "" });
        setmail("")
        toastAlert("success", message, "success");
      }
      else {
        toastAlert("error", message, "error");
      }
    }
    catch (err) {

    }
  }

  useEffect(() => {
    getpresale()
    getpricechart()
    getchart()
  }, [])
  useEffect(() => {
    getpackage()
    getAllNews()
    getAllLaunchPad()
    getAllFaq()
  }, [])
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  //   useEffect(()=>{
  //     // document.getElementById("myCode").innerHTML 
  // },[])

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [])

  const getAllFaq = async () => {
    try {
      const { result } = await getAllFaqAction()
      setfaq(result)
    }
    catch (err) {
    }
  }

  const getAllLaunchPad = async () => {
    try {
      const { result } = await getAllLaunchPadAction()
      setLaunch(result)
    }
    catch (err) {
    }
  }



  const getAllNews = async () => {

    try {

      const data = await getAllNewsUpdates()
      if (data.result.length > 0) {
        setNews(data.result)
      } else {
        setNews([])
      }
    } catch (error) {
      console.log(error);
    }
  }

  const convetHtmlTag = (ele) => {


    return <div>{ReactHtmlParser(ele)}</div>;

  }


  const renderer = ({ days, hours, minutes, seconds }) => {
    return (
      <div className="timer_panel">
        <span data-aos="flip-up" data-aos-delay="300">
          <span className="timer_time">{zeroPad(days)}</span>
          <span className="timer_label">Days</span>
        </span>
        <span className="timer_dots" data-aos="fade-up" data-aos-delay="300">:</span>
        <span data-aos="flip-up" data-aos-delay="600">
          <span className="timer_time">{zeroPad(hours)}</span>
          <span className="timer_label">Hours</span>
        </span>
        <span className="timer_dots" data-aos="fade-up" data-aos-delay="600">:</span>
        <span data-aos="flip-up" data-aos-delay="900">
          <span className="timer_time">{zeroPad(minutes)}</span>
          <span className="timer_label">Minutes</span>
        </span>
        <span className="timer_dots" data-aos="fade-up" data-aos-delay="900">:</span>
        <span data-aos="flip-up" data-aos-delay="1200">
          <span className="timer_time" >{zeroPad(seconds)}</span>
          <span className="timer_label sec" >Seconds</span>
        </span>
      </div>
    );
  };
  const chartData = {
    options: {
      chart: {
        id: 'hodl-defi-chart',
        toolbar: {
          autoSelected: "pan",
          show: false
        }
      },
      colors: ["#FCD434"],
      grid: {
        borderColor: "#555",
        clipMarkers: false,
        yaxis: {
          lines: {
            show: false
          }
        }
      },
      dataLabels: {
        enabled: false
      },
      zoom: {
        enabled: false,
      },
      xaxis: {
        categories: tbxchart && tbxchart.length > 0 ? tbxchart.map((data) => moment(data.createdAt).format('DD-MM-YYYY')) : ['08/09/2023', '09/09/2023', '10/09/2023'],
      },
      tooltip: {
        theme: "dark",
      },
    },
    fill: {
      gradient: {
        enabled: true,
        opacityFrom: 0.55,
        opacityTo: 0
      }
    },
    series: [
      {
        name: 'TBXprice',
        data: tbxchart && tbxchart.length > 0 ? tbxchart.map((data) => data.tbxprice) : ['100', '20', '40'],
      },

    ],
    tooltip: {
      theme: "dark"
    },
  };
  const userchartData = {
    options: {
      chart: {
        id: "hodl-defi-chart",
        toolbar: {
          autoSelected: "pan",
          show: false,
        },
      },
      colors: ["#FCD434"],
      grid: {
        borderColor: "#555",
        clipMarkers: false,
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      zoom: {
        enabled: false,
      },
      xaxis: {
        categories:
          userchart && userchart.length > 0
            ? userchart.map((data) => moment(data._id).format("DD-MM-YYYY"))
            : ['08/09/2023', '09/09/2023', '10/09/2023'],
      },
      tooltip: {
        theme: "dark",
      },
    },
    fill: {
      gradient: {
        enabled: true,
        opacityFrom: 0.55,
        opacityTo: 0,
      },
    },
    series: [
      {
        name: "Total Users",
        data:
          userchart && userchart.length > 0
            ? userchart.map((data) => data.total_count)
            : ['10', '20', '40'],
      },
    ],
    tooltip: {
      theme: "dark",
    },
  }

  const DownloadFile = (url, filename) => {
    axios.get(url, {
      responseType: 'blob',
    })
      .then((res) => {
        fileDownload(res.data, filename)
      })
  }

  return (
    <div  >
      <ScrollToTopOnMount />
      <Navbar />
      <div className="page_header">
        <div className="container">
          <h1 data-aos="fade-up" data-aos-duration="1000">REVOLUTIONIZING THE FUTURE OF FINANCE</h1>
          <p className="para" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300" >BUILDING A DECENTRALIZED FINANCIAL ECOSYSTEM FOR TOMORROW TBX STAKING</p>
        
          <img src={require("../assets/images/banner_animation_08.gif")} className="img-fluid" alt="img" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="600" />
        </div>
      </div>
      <div className="main_wrapper">
        <section className="token">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div >
                  <div className="box">
                    <div className="head" data-aos="fade-up" data-aos-duration="1000"  >
                      <h2 className="h2tag" ><span>Presale</span> Token</h2>
                      <p className="para"> Early Access and low Prices: Presale participants typically have the opportunity to purchase tokens at a low price compared to the price at which they will be available during the public sale or on exchanges once the project launches.</p>
                      <p className="para" >Potential for Higher Returns: By purchasing tokens at a lower price during the presale, participants may experience higher returns on investment if the token's value appreciates once it's listed on exchanges or gains traction in the market.
Limited availability</p></div>
                  </div>
                </div>
                {/* <Countdown date="2023-09-30T01:02:03" renderer={renderer} /> */}
                <Countdown date={new Date(presaledata.startTimeStamp)} renderer={renderer} />
                <div className="row">

                  
                  <h2 className="mytag"><span>1USDT = {tbxPrice}TBX</span> </h2>

                  <div className="col-md-6 chart-width" data-aos="fade-up" data-aos-duration="1000">
                  <p className="para">TBX Rate</p>
                    <Chart
                      options={chartData.options}
                      series={chartData.series}
                      type="area" width="100%"
                      height={400}
                    />
                    {/* <img src={require("../assets/images/chart.png")} className="img-fluid w-100" alt="img"/>  */}
                  </div>

                  <div className="col-md-6" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300" >
                    
                  <p className="para">Total Users</p>
                      <div className="chart-width">
                      <Chart
                        options={userchartData.options}
                        series={userchartData.series}
                        type="area"
                        width="100%"
                        height={400}
                      />
                    </div>
                    {/* <img src={require("../assets/images/chart2.png")} className="img-fluid w-100" alt="img"/>  */}
                  </div>
                </div>
              </div>


            </div>
          </div>
        </section>

        {
          news && news.length > 0 &&
          <section className="news">
            <div className="container">
              <div className="head" data-aos="fade-up" data-aos-duration="1000">
                <h2 className="h2tag"><span>News</span> and Updates</h2>
                {/* <p className="para">Integer tempus diam id purus tincidunt, eget semper velit congue. Sed ultricies laoreet ligula, vel iaculis elit sodales vitae. </p> */}
              </div>

              {
                news && news.length > 0 ?

                  news.map((ele) => {
                    return (
                      <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300">
                        <div className="box" >
                          <div className="row" >
                            <div className="col-lg-3" >
                              <img src={`${config.serverURL}/news/${ele.Image}`} className="img-fluid" alt="img" />
                            </div>
                            <div className="col-lg-4" >
                              <h5>{ele.title} </h5>
                              <p>{moment(ele.createdAt).format('LL')}</p>
                            </div>
                            <div className="col-lg-5" >
                              <span>{ele.description}</span>
                              {/* <span>{convetHtmlTag(ele.content)}</span> */}
                              <Link to={`/news/${ele.link}`}>Read Article</Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })

                  : <></>
              }

              {/* <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="600" >
              <div className="box" >
                <div className="row" >
                  <div className="col-lg-3" >
                    <img src={require("../assets/images/img2.png")} className="img-fluid" alt="img" />
                  </div>
                  <div className="col-lg-4">
                    <h5>Aenean condimentum mi nec erat rhoncus euismod.</h5>
                    <p>August 25, 2023</p>
                  </div>
                  <div className="col-lg-5">
                    <span>Mauris ante nisl, congue vitae turpis sit amet, sagittis blandit nunc. Fusce ut libero eget erat sodales dapibus. Vestibulum et orci vitae mi lacinia porta eu et ligula.  </span>
                    <Link to="#">Read Article</Link>
                  </div>
                </div>
              </div>
            </div>
            <div data-aos="fade-up" data-aos-duration="1000" data-aos-delay="900">
              <div className="box"  >
                <div className="row" >
                  <div className="col-lg-3" >
                    <img src={require("../assets/images/img3.png")} className="img-fluid" alt="img" />
                  </div>
                  <div className="col-lg-4" >
                    <h5> Donec consectetur leo magna, ut viverra sapien tempor id. </h5>
                    <p>August 25, 2023</p>
                  </div>
                  <div className="col-lg-5" >
                    <span> Fusce ut libero eget erat sodales dapibus. Vestibulum et orci vitae mi lacinia porta eu et ligula. Mauris ante nisl, congue vitae turpis sit amet, sag`1ittis blandit nunc.  </span>
                    <Link to="#">Read Article</Link>
                  </div>
                </div>
              </div>
            </div> */}
              {/* <div className="text-center mt-4 pt-3" data-aos="fade-up" data-aos-duration="1000">
              <button className="primary_btn px-5">View All Articles</button>
            </div> */}
            </div>
          </section>
        }
        <section className="benefits">
          <div className="benefitbg" >
            <div className="container">
              <div className="head mb-4 pb-2" data-aos="fade-up" data-aos-duration="1000">
                <h2 className="h2tag"><span>TBX</span>   Benefits</h2>
                <p className="para">Unlocking the Potential: TBX Token Usage</p>
              </div>
              <div className="box" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300">
                <div className="row">
                  <div className="col-lg-5" >
                    <div className="num">01</div>
                    <h5>TBX Swap</h5>
                  </div>
                  <div className="col-lg-2" >
                    <img src={require("../assets/images/b1.png")} className="img-fluid" alt="img" />
                  </div>
                  <div className="col-lg-5" >
                    <span>Swiftly exchange various cryptocurrencies with TBX, ensuring smooth and efficient transactions within the platform.</span>
                  </div>
                </div>
              </div>
              <div className="box" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="600">
                <div className="row">
                  <div className="col-lg-5" >
                    <div className="num">02</div>
                    <h5>Lotto</h5>
                  </div>
                  <div className="col-lg-2" >
                    <img src={require("../assets/images/b2.png")} className="img-fluid" alt="img" />
                  </div>
                  <div className="col-lg-5" >
                    <span>Participate in lotteries using TBX for a chance to win attractive prizes, enhancing user engagement and excitement. </span>
                  </div>
                </div>
              </div>
              <div className="box" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="900">
                <div className="row">
                  <div className="col-lg-5" >
                    <div className="num">03</div>
                    <h5>NFT Projects</h5>
                  </div>
                  <div className="col-lg-2" >
                    <img src={require("../assets/images/b3.png")} className="img-fluid" alt="img" />
                  </div>
                  <div className="col-lg-5" >
                    <span>Dive into the world of NFTs, leveraging TBX for transactions and involvement in NFT-related projects and markets.</span>
                  </div>
                </div>
              </div>
              <div className="box" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="900">
                <div className="row">
                  <div className="col-lg-5" >
                    <div className="num">04</div>
                    <h5>X-Pay Global Card</h5>
                  </div>
                  <div className="col-lg-2" >
                    <img src={require("../assets/images/b1.png")} className="img-fluid" alt="img" />
                  </div>
                  <div className="col-lg-5" >
                    <span>Seamlessly integrate TBX with the X-Pay Global Card, allowing for easy and secure real-world transactions, merging the realm of cryptocurrencies with traditional.</span>
                  </div>
                </div>
              </div>
              <div className="box" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="900">
                <div className="row">
                  <div className="col-lg-5" >
                    <div className="num">05</div>
                    <h5>AI Trading Bot</h5>
                  </div>
                  <div className="col-lg-2" >
                    <img src={require("../assets/images/b2.png")} className="img-fluid" alt="img" />
                  </div>
                  <div className="col-lg-5" >
                    <span>Optimize trading strategies and potential returns by utilizing TBX to access sophisticated AI-powered trading bots.</span>
                  </div>
                </div>
              </div>
              <div className="box" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="900">
                <div className="row">
                  <div className="col-lg-5" >
                    <div className="num">06</div>
                    <h5>Games</h5>
                  </div>
                  <div className="col-lg-2" >
                    <img src={require("../assets/images/b3.png")} className="img-fluid" alt="img" />
                  </div>
                  <div className="col-lg-5" >
                    <span>Enjoy engaging gaming experiences by using TBX as a mode of in-game currency, adding entertainment value to the ecosystem.</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="works">
          <div className="container">
            <div className="row">
              <div className="col-lg-5" data-aos="fade-up" data-aos-duration="1000">
                <h2 className="h2tag mb-5">How It <span> Works</span></h2>
                <ul>
                  <li>In ipsum nunc, maximus non faucibus eget, interdum ut massa. Nam porttitor libero nec lacus pretium, sed viverra eros consequat. </li>
                  <li>Suspendisse dolor turpis, hendrerit in est a, pretium porttitor leo. Phasellus accumsan a augue quis eleifend. Vivamus ut aliquam sem. </li>
                  <li>Phasellus accumsan a augue quis eleifend. Vivamus ut aliquam sem. Suspendisse dolor turpis, hendrerit in est a, pretium porttitor leo.</li>
                </ul>
              </div>
              <div className="col-lg-7 m-auto" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300">
                <div className="img_box">
                  <img src={require("../assets/images/work.png")} className="img-fluid" alt="img" />
                  <img src={require("../assets/images/work_round.png")} className="img-fluid round" alt="img" />
                </div>
              </div>
            </div>
          </div>
        </section>


        <section className="packages">
          <div className="overflow-hidden py-4">
            <div className="container">
              <div className="head" data-aos="fade-up" data-aos-duration="1000">
                <h2 className="h2tag"><span>TBX</span> Packages</h2>
                <p className="para mb-5">We have set a rate of 4 TBX per 1usdt for the tbx token during the pre-sale. If we want to change the pricing according to total supply then we can change it</p>
                <button className="primary_btn mb-5" style={{"cursor":"default"}}>View all packages</button>
              </div>
              <div className="row" data-aos="fade-up" data-aos-duration="1000">
                <div className="col-xxl-10 mx-auto">
                  <div className="row">
                    <div className="col-lg-2">
                      {list1 && list1.length > 0 && list1.map((item, key) => (
                        <>
                          <div className="box">
                            <h5>{item.packageprice} TBX</h5>
                            <h5>{item.usdtprice} USDT</h5>
                          </div>
                        </>
                      ))}
                      {/* <div className="box">
                        <h5>100 TBX</h5>
                        <h5>25 USDT</h5>
                    </div>
                    <div className="box">
                        <h5>500 TBX</h5>
                        <h5>125 USDT</h5>
                    </div>
                    <div className="box">
                        <h5>1000 TBX</h5>
                        <h5>250 USDT</h5>
                    </div>                     */}
                    </div>
                    <div className="col-lg-8">
                      <div className="img_box">
                        <div className="ball ball1" ></div>
                        <div className="ball ball2" ></div>
                        <div className="ball ball3" ></div>
                        <div className="ball ball4" ></div>
                        <div className="ball ball5" ></div>
                        <div className="ball ball6" ></div>
                        <img src={require("../assets/images/package_center.png")} className="img-fluid center" alt="img" />
                        <img src={require("../assets/images/package_circle.png")} className="img-fluid circle" alt="img" />
                      </div>
                    </div>
                    <div className="col-lg-2 right">
                      {/* <div className="box">
                        <h5>2000 TBX</h5>
                        <h5>500 USDT</h5>
                    </div>
                    <div className="box">
                        <h5>5000 TBX</h5>
                        <h5>1250 USDT</h5>
                    </div>
                    <div className="box">
                        <h5>10000 TBX</h5>
                        <h5>2500 USDT</h5>
                    </div>                     */}
                      {list2 && list2.length > 0 && list2.map((item, key) => (
                        <>
                          <div className="box">
                            <h5>{item.packageprice} TBX</h5>
                            <h5>{item.usdtprice} USDT</h5>
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </section>

        <section className="how">
          <div className="container">
            <div className="head" data-aos="fade-up" data-aos-duration="1000">
              <h2 className="h2tag">How To Start <span> TBX Stake</span></h2>
              <p className="para mb-5">Nullam ornare mattis tortor, at dictum diam maximus sed. Aliquam mollis leo a neque porttitor ullamcorper.</p>
            </div>
            <div className="box" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300">
              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Step 1</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Step 2</button>
                </li>
                <li class="nav-item" role="presentation">
                  <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">Step 3</button>
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                  <ul>
                    <li>Aliquam maximus massa nec nulla suscipit, vel rhoncus augue.</li>
                    <li>In congue ultricies ex, id tincidunt est malesuada mollis.</li>
                    <li>Mauris in faucibus orci. Aenean mollis tincidunt ultricies. </li>
                    <li>Praesent imperdiet ipsum orci, a rhoncus enim tempus id.</li>
                    <li>Quisque tincidunt lacus et eros congue, sit amet consequat orci hendrerit.</li>
                  </ul>
                </div>
                <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                  <ul>
                    <li>Aliquam maximus massa nec nulla suscipit, vel rhoncus augue.</li>
                    <li>In congue ultricies ex, id tincidunt est malesuada mollis.</li>
                    <li>Mauris in faucibus orci. Aenean mollis tincidunt ultricies. </li>
                    <li>Praesent imperdiet ipsum orci, a rhoncus enim tempus id.</li>
                    <li>Quisque tincidunt lacus et eros congue, sit amet consequat orci hendrerit.</li>
                  </ul>
                </div>
                <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                  <ul>
                    <li>Aliquam maximus massa nec nulla suscipit, vel rhoncus augue.</li>
                    <li>In congue ultricies ex, id tincidunt est malesuada mollis.</li>
                    <li>Mauris in faucibus orci. Aenean mollis tincidunt ultricies. </li>
                    <li>Praesent imperdiet ipsum orci, a rhoncus enim tempus id.</li>
                    <li>Quisque tincidunt lacus et eros congue, sit amet consequat orci hendrerit.</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="supported">
          <div className="container">
            <div className="head" data-aos="fade-up" data-aos-duration="1000">
              <h2 className="h2tag"><span>Supported</span> Cryptocurrencies</h2>
              {/* <p className="para mb-5">Aliquam mollis leo a neque porttitor ullamcorper. Nullam ornare mattis tortor, at dictum diam maximus sed. </p> */}
              <button className="primary_btn mb-5" style={{"cursor":"default"}}> See Our Cryptocurrencies</button>
            </div>
            <div className="row mt-5">
              <div className="col-lg-3" data-aos="flip-up" data-aos-duration="600">
                <div className="box">
                  <img src={require("../assets/images/coin1.png")} className="img-fluid" alt="img" />
                  <h5>Bitcoin</h5>
                  <p>(BTC)</p>
                </div>
              </div>
              <div className="col-lg-3" data-aos="flip-up" data-aos-duration="600" data-aos-delay="300">
                <div className="box">
                  <img src={require("../assets/images/coin2.png")} className="img-fluid" alt="img" />
                  <h5>Ethereum</h5>
                  <p>(ETH)</p>
                </div>
              </div>
              <div className="col-lg-3" data-aos="flip-up" data-aos-duration="600" data-aos-delay="600">
                <div className="box">
                  <img src={require("../assets/images/coin3.png")} className="img-fluid" alt="img" />
                  <h5>Tether</h5>
                  <p>(USDT)</p>
                </div>
              </div>
              <div className="col-lg-3" data-aos="flip-up" data-aos-duration="600" data-aos-delay="900">
                <div className="box mb-0">
                  <img src={require("../assets/images/coin4.png")} className="img-fluid" alt="img" />
                  <h5>US Dollar</h5>
                  <p>(USD)</p>
                </div>
              </div>
            </div>

          </div>
        </section>

        <section className="security">
          <div className="container">
            <div className="head" data-aos="fade-up" data-aos-duration="1000">
              <h2 className="h2tag"><span>Security</span>  Measures</h2>
              {/* <p className="para mb-5">In ipsum nunc, maximus non faucibus eget, interdum ut massa. Nam porttitor libero nec lacus pretium, sed viverra eros consequat. </p> */}
            </div>
            <div className="row">
              <div className="col-lg-4 d-flex" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="100" >
                <div className="box">
                  <img src={require("../assets/images/security.png")} className="img-fluid" alt="img" />
                  <h5><span>Decentralized</span></h5>
                  <p>TBX operates on a decentralized platform, ensuring robust safety and security. The decentralization minimizes single points of failure and enhances resistance against cyber threats. Additionally, blockchain technology, which underpins this decentralization, utilizes cryptographic mechanisms to secure transactions and data, making it highly resilient against unauthorized access and tampering. The implementation of smart contracts further fortifies security by automating processes and reducing potential vulnerabilities. Regular audits, continuous monitoring, and community involvement contribute to a safer ecosystem for TBX and its users.</p>
                </div>
              </div>
              <div className="col-lg-4 d-flex" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300" >
                <div className="box">
                  <img src={require("../assets/images/security.png")} className="img-fluid" alt="img" />
                  <h5><span>smartcontract</span></h5>
                  <p> <span style={{color:"#FCD434"}}>Immutability:</span>Once a smart contract is deployed on the blockchain, its code and execution logic cannot be altered or tampered with, 
providing a high level of security against unauthorized modifications.</p>
<p> <span style={{color:"#FCD434"}}>Cryptographic Security:</span>Smart contracts use cryptographic functions to secure transactions, providing confidentiality and authentication of participants .</p>
<p> <span style={{color:"#FCD434"}}>Consensus Mechanisms:</span>Once a smart contract is deployed on the blockchain, its code and execution logic cannot be altered or tampered with, 
providing a high level of security against unauthorized modifications.</p>
                </div>
              </div>
              <div className="col-lg-4 d-flex" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="600" >
                <div className="box">
                  <img src={require("../assets/images/security.png")} className="img-fluid" alt="img" />
                  <h5><span>Bep 20</span> </h5>
                  <p> The TBX project on the BEP-20 (Binance Smart Chain) blockchain ensures safety and security through the robustness of the Binance Smart Chain infrastructure while capitalizing on advantages such as high-speed transactions, low fees, and seamless interoperability.</p>
                
                  <p> <span style={{color:"#FCD434"}}>Open Source Auditing:</span>Smart contract code is often open source, allowing for community and third-party audits to identify vulnerabilities and enhance security.</p>
                </div>
              </div>
            </div>

          </div>
        </section>

        <section className="roadmap">
          <div className="roadmapbg">
            <div className="container">
              <h2 className="h2tag mb-5" data-aos="fade-up" data-aos-duration="1000">Roadmap <span> TBX Staking</span></h2>
              <div data-aos="fade-up" data-aos-duration="1000">
                <div className="box ylw" >
                  <div className="row">
                    <div className="col-lg-1">

                    </div>
                    <div className="col-lg-5">
                      <span>[ December 2021 ]</span>
                      <h5>The TBX project embarked on its ecosystem journey in December 2021, laying out an extensive roadmap for innovation and growth.</h5>
                    </div>
                    <div className="col-lg-6 m-auto">
                      <p className="para">Duis facilisis felis justo, sit amet accumsan libero molestie vitae. Phasellus porta justo ligula, luctus tempor eros tempus vel.</p>
                    </div>
                  </div>
                </div>
                <div className="box ylw" >
                  <div className="row">
                    <div className="col-lg-1">

                    </div>
                    <div className="col-lg-5">
                      <span>[ JUL -2023 ]</span>
                      <h5>Rebalancing Trade.</h5>
                    </div>
                    <div className="col-lg-6 m-auto">
                      <p className="para">Employing strategies to optimize trade portfolios, ensuring a balanced and stable market.</p>
                    </div>
                  </div>
                </div>
                <div className="box ylw" >
                  <div className="row">
                    <div className="col-lg-1">

                    </div>
                    <div className="col-lg-5">
                      <span>[ JUN -2023 ]</span>
                      <h5> TBX Presale</h5>
                    </div>
                    <div className="col-lg-6 m-auto">
                      <p className="para"> Introducing a limited-time opportunity for early investors to acquire TBX tokens at favorable rates before the public offering</p>
                    </div>
                  </div>
                </div>
                <div className="box" >
                  <div className="row">
                    <div className="col-lg-1">

                    </div>
                    <div className="col-lg-5">
                      <span>[ MAY -2023 ]</span>
                      <h5> TBX Staking</h5>
                    </div>
                    <div className="col-lg-6 m-auto">
                      <p className="para">Allowing users to lock up TBX tokens, earning rewards and fostering a sense of community participation.</p>
                    </div>
                  </div>
                </div>
                <div className="box" >
                  <div className="row">
                    <div className="col-lg-1">

                    </div>
                    <div className="col-lg-5">
                      <span>[ APR -2023 ]</span>
                      <h5> TBX Swap</h5>
                    </div>
                    <div className="col-lg-6 m-auto">
                      <p className="para">Streamlining the exchange of TBX tokens for other assets within the TBX ecosystem, promoting liquidity and accessibility.</p>
                    </div>
                  </div>
                </div>
                <div className="box pb-4" >
                  <div className="row">
                    <div className="col-lg-1">

                    </div>
                    <div className="col-lg-5">
                      <span>[ MAR -2023 ]</span>
                      <h5>Founders Club</h5>
                    </div>
                    <div className="col-lg-6 m-auto">
                      <p className="para">Providing exclusive benefits and recognition to early supporters, forming a dedicated community of advocates.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {
                launchpad && launchpad.length > 0 &&
        <section className="launchpad">
          <div className="container">
            <h2 className="h2tag" data-aos="fade-up" data-aos-duration="1000" >Launchpad <span>TBX</span></h2>
            <div className="row">
              {
                launchpad && launchpad.length > 0 && launchpad.map((item) => {
                  return (
                    <div className="col-lg-4" data-aos="fade-up" data-aos-duration="1000">
                      <div className="box">
                        {
                          item.Extension == 'image' ?
                            <>
                              <img src={config.serverURL + 'launchpad/' + item.Filename} className="img-fluid" alt="img" style={{width:"356px",height:"201px"}} />
                              <p className="para" dangerouslySetInnerHTML={{ __html: item.content }} />
                              <button className="primary_btn" onClick={() => DownloadFile(config.serverURL + 'launchpad/' + item.Filename, item.Filename)}> Download Image</button>
                            </>
                            :
                            item.Extension == 'video' ?
                              <>
                                <video
                                  src={config.serverURL + 'launchpad/' + item.Filename}
                                  style={{width:"356px",height:"201px"}}
                                  type="video/mp4"
                                  alt="videos"
                                  className="img-fluid"
                                  controls
                                />
                                <p className="para" dangerouslySetInnerHTML={{ __html: item.content }} />
                                <button className="primary_btn" onClick={() => DownloadFile(config.serverURL + 'launchpad/' + item.Filename, item.Filename)}>Download Video</button>
                              </>

                              : item.Extension == 'application' ?
                                <>
                                  <a>
                                    <embed src={config.serverURL + 'launchpad/' + item.Filename} className="img-fluid" alt="img" style={{width:"356px",height:"201px"}} />

                                    {/* <img src={require("../assets/images/l1.png")} className="img-fluid" alt="img" /> */}
                                  </a>
                                  <p className="para" dangerouslySetInnerHTML={{ __html: item.content }} />
                                  <button className="primary_btn" onClick={() => DownloadFile(config.serverURL + 'launchpad/' + item.Filename, item.Filename)}>Download PDF</button>

                                </>
                                : <></>
                        }

                        {/* <a href="#">View more PDF</a> */}
                      </div>
                    </div>
                  )
                })
              }


            </div>
          </div>
        </section>
}
        {/* <section className="launchpad">
          <div className="container">
            <h2 className="h2tag" data-aos="fade-up" data-aos-duration="1000" >Launchpad <span>TBX</span></h2>
            <div className="row">
              <div className="col-lg-4" data-aos="fade-up" data-aos-duration="1000">
                <div className="box">
                  <img src={require("../assets/images/l1.png")} className="img-fluid" alt="img" />
                  <p className="para">Morbi sed pulvinar mi. Nunc eu erat vel risus posuere lacinia sit amet ac metus. Class aptent taciti sociosqu ad litora torquent.</p>
                  <button className="primary_btn">Download PDF</button>
                </div>
              </div>
              <div className="col-lg-4" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300">
                <div className="box">
                  <img src={require("../assets/images/l2.png")} className="img-fluid" alt="img" />
                  <p className="para">Morbi sed pulvinar mi. Nunc eu erat vel risus posuere lacinia sit amet ac metus. Class aptent taciti sociosqu ad litora torquent.</p>
                  <button className="primary_btn">Download Video  </button>
                </div>
              </div>
              <div className="col-lg-4" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="600">
                <div className="box">
                  <img src={require("../assets/images/l3.png")} className="img-fluid" alt="img" />
                  <p className="para">Morbi sed pulvinar mi. Nunc eu erat vel risus posuere lacinia sit amet ac metus. Class aptent taciti sociosqu ad litora torquent.</p>
                  <button className="primary_btn">Download Image</button>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section className="testimonial">
          <div className="container">
            <div className="head" data-aos="fade-up" data-aos-duration="1000">
              <h2 className="h2tag">Our  <span>Testimonials</span></h2>
            </div>
            <Slider {...settings}>
              <div>
                <div className="box" data-aos="fade-up" data-aos-duration="1000" >
                  <img src={require("../assets/images/testimonial.png")} className="img-fluid" alt="img" />
                  <p className="para">Curabitur semper elementum lectus, id ultrices erat bibendum quis. Suspendisse justo felis, aliquet ac pretium vel, bibendum tristique mauris. Praesent mattis maximus neque, sit amet tincidunt lorem. Suspendisse faucibus, velit ac consequat volutpat, nibh nunc ultricies erat, eu vestibulum mauris lacus et erat.</p>
                  <div className="boxes" >
                    <img src={require("../assets/images/testimonial_1.png")} className="img-fluid d-inline" alt="img" />
                    <h5>Jacob Jones</h5>
                    <span>Cheif Product Officer</span>
                  </div>
                </div>
              </div>
              {/* <div>
            <div className="box" >
            <img src={require("../assets/images/testimonial.png")} className="img-fluid" alt="img"/> 
            <p className="para">Curabitur semper elementum lectus, id ultrices erat bibendum quis. Suspendisse justo felis, aliquet ac pretium vel, bibendum tristique mauris. Praesent mattis maximus neque, sit amet tincidunt lorem. Suspendisse faucibus, velit ac consequat volutpat, nibh nunc ultricies erat, eu vestibulum mauris lacus et erat.</p>
            <div className="boxes" >
             <img src={require("../assets/images/testimonial_1.png")} className="img-fluid d-inline" alt="img"/> 
             <h5>Jacob Jones</h5>
             <span>Cheif Product Officer</span>
            </div>
            </div>
            </div>
            <div>
            <div className="box" >
            <img src={require("../assets/images/testimonial.png")} className="img-fluid" alt="img"/> 
            <p className="para">Curabitur semper elementum lectus, id ultrices erat bibendum quis. Suspendisse justo felis, aliquet ac pretium vel, bibendum tristique mauris. Praesent mattis maximus neque, sit amet tincidunt lorem. Suspendisse faucibus, velit ac consequat volutpat, nibh nunc ultricies erat, eu vestibulum mauris lacus et erat.</p>
            <div className="boxes" >
             <img src={require("../assets/images/testimonial_1.png")} className="img-fluid d-inline" alt="img"/> 
             <h5>Jacob Jones</h5>
             <span>Cheif Product Officer</span>
            </div>
            </div>
            </div> */}
            </Slider>
          </div>
        </section>
        {
                  faqList && faqList.length > 0 &&
        <section className="faq">
          <div className="container">
            <div className="head" data-aos="fade-up" data-aos-duration="1000"><h2 className="h2tag">FAQs</h2></div>
            <div class="accordion" id="accordionExample">
             
                {
                  faqList && faqList.length > 0 && faqList.map((item, index) => {
                    return (
                      <>
                       <div class="accordion-item" data-aos="fade-up" data-aos-duration="1000">
                        <h2 class="accordion-header" id={"heading"+index}>
                          <button class={index=='0'?"accordion-button":"accordion-button collapsed"} type="button" data-bs-toggle="collapse" data-bs-target={"#collapse"+index} aria-expanded={item=='0'?'true':'false'} aria-controls={"collapse"+index}>
                            {index + 1}. {item.question}
                          </button>
                        </h2>
                        <div id={"collapse" + index} class={index == '0' ? "accordion-collapse collapse show" : "accordion-collapse collapse"} aria-labelledby={"heading" + index} data-bs-parent="#accordionExample">
                          <div class="accordion-body">
                            <p class="para">{item.answer}</p>
                          </div>
                        </div>
                        </div>
                      </>
                    )
                  })
                }

             
            </div>
          </div>
        </section>
}
        <section className="updates">
          <div className="container">
            <div className="row">
              <div className="col-lg-6" data-aos="fade-up" data-aos-duration="1000">
                <h2 className="h2tag">Want to get the latest news of <span>TBX?</span></h2>
                <p className="para">Let's probe the brilliant investing plan of the Tribex token.</p>
              </div>
              <div className="col-lg-6 m-auto" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="300">
                <div class="input-group mb-3 myinput">
                  <span class="input-group-text" id="basic-addon1">
                    <img src={require("../assets/images/email.png")} className="img-fluid" alt="img" />
                  </span>
                  <input type="text" class="form-control" placeholder="Enter your Email Address" aria-label="Username" aria-describedby="basic-addon1" value={mail} onChange={handleMail} />

                  <span class="input-group-text" id="basic-addon1"><span className="subscribe" style={{"cursor":"pointer"}} onClick={() => { saveEmail() }} >Subscribe</span></span>

                </div>
                {showerror && showerror.emailerr != "" && (
                  <p style={{ color: "red", fontSize: "17px" }}>
                    {showerror.emailerr}
                  </p>
                )}
              </div>
            </div>
          </div>
        </section>


        <Footer />
      </div>
    </div>
  );
}
