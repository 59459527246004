import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";
import Tree from "react-animated-tree-v2";
import { CopyToClipboard } from "react-copy-to-clipboard";
import user from "../assets/images/user.svg";
import refferal from "../assets/images/refferal.svg";
import investment from "../assets/images/investment.svg";
import income from "../assets/images/income.svg";
import position from "../assets/images/position.svg";
import search from "../assets/images/search.svg";
import { plus, minus, close } from "../components/icons.js";
import { toastAlert } from "../helper/toastAlert";
import { getuserDetailsAction } from "../helper/multicall";
import { useSelector } from "react-redux";
import Web3 from "web3";
import { connection } from "../helper/connection";
import config from "../config/config";
import Abi from "../ABI/Abi.json";
import TokenAbi from '../ABI/TokenAbi.json'
import { getUserchart, getInvestmentchart} from "../API/UserActions";
import moment from "moment";
import Chart from "react-apexcharts";
import Withdraw from "../components/Withdraw.js";
import Staking from "../components/Staking.js";
import { convert } from "../helper/convert";
import {saveTransaction} from "../API/UserActions"
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TelegramShareButton,
  TelegramIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import { isEmpty } from "../helper/isEmpty";
import {formatNumber} from '../helper/custommath';
import { bignumber, multiply, divide, add } from 'mathjs'
export default function Dashboard() {
  const walletConnection = useSelector((state) => state.walletConnection);
  const [userdata, setuserdata] = useState({});
  const [list1, setlist1] = useState([]);
  const [treedata, settreedata] = useState([]);
  const [userchart, setuserchart] = useState({});
  const [investmentchart, setinvestmentchart] = useState({});
  const [regbtn, setregbtn] = useState(false);
  const [rejoinbtn, setrejoinbtn] = useState(false);
  const [load, setload] = useState(false);
  const [currplan, setcurrplan] = useState('');
  const [claimhash, setclaimhash] = useState('');
  const [copytext, setcopytext] = useState('');
  const [copytextone, setcopytextone] = useState('');
  const [loader, setloader] = useState(false);
  const [treeList, settreeList] = useState([]);
  const [tbxPrice, settbxPrice] = useState('')
  const [refferaladdress, setrefferaladdress] = useState("");
  const [showerror, setshowerror] = useState({});
  console.log(walletConnection, "walletConnection");

  const CustomizedIconTree = (props) => (
    <Tree
      icons={{ plusIcon: plus, minusIcon: minus, closeIcon: close }}
      {...props}
    />
  );
  async function getpackage() {
    try {
      var web3 = new Web3(config.netWorkUrl);
      var contractAddress = config.contractAddress;
      var Contract = new web3.eth.Contract(Abi, contractAddress);
      var tbxprice = await Contract.methods.tokenPrice().call();
      tbxprice=tbxprice/1e6;
      settbxPrice(tbxprice)
      var arr1 = [];
      var arr2 = [];
      for (var i = 1; i <= 6; i++) {
        var obj1 = {};
        var data = await Contract.methods.PACKAGE_PRICE(i).call();



        var pkgprice = bignumber(data);
        var decimal = bignumber(1e18);
        var price = divide(pkgprice, decimal);
        price = price.toString();

        var tbxrate = bignumber(tbxprice);
        var tokenVal = divide(price, tbxrate);
        tokenVal =tokenVal.toString()



        obj1.packagenum = i;
        obj1.packageprice = await formatNumber (price,4);
        obj1.usdtprice = await formatNumber(tokenVal,4);
        arr1.push(obj1);
      }
      setlist1(arr1);
      console.log(arr1, "arr123112");
    } catch (err) {
      console.log(err, "eeeeeeee");
    }
  }

  async function multicall() {
    try {
      setloader(true)
      var userprofile = await getuserDetailsAction();
      console.log(userprofile, "userprofile31");

      setuserdata(userprofile);
      setloader(false)
    } catch (err) {
      console.log(err, "eeeeeee");
    }
  }
  async function getchart() {
    let { result, success } = await getUserchart();
    console.log(result, "result222");
    setuserchart(result);
  }
  async function getinvestchart() {
    var data = {
      address: walletConnection.address,
    };
    let { result, success } = await getInvestmentchart(data);
    console.log(result, "resultsetinvestmentchart");
    setinvestmentchart(result);
  }

  useEffect(() => {
    getpackage();
    getchart();
  }, []);
  useEffect(() => {
    multicall();
    getinvestchart();
    getreferalusers(walletConnection.address)
  
  }, [walletConnection.address]);
  const chartData = {
    options: {
      chart: {
        id: "hodl-defi-chart",
        toolbar: {
          autoSelected: "pan",
          show: false,
        },
      },
      colors: ["#FCD434"],
      grid: {
        borderColor: "#555",
        clipMarkers: false,
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      zoom: {
        enabled: false,
      },
      xaxis: {
        categories:
          userchart && userchart.length > 0
            ? userchart.map((data) => moment(data._id).format("DD-MM-YYYY"))
            : [],
      },
      tooltip: {
        theme: "dark",
      },
    },
    fill: {
      gradient: {
        enabled: true,
        opacityFrom: 0.55,
        opacityTo: 0,
      },
    },
    series: [
      {
        name: "Total Users",
        data:
          userchart && userchart.length > 0
            ? userchart.map((data) => data.total_count)
            : [],
      },
    ],
    tooltip: {
      theme: "dark",
    },
  };
  const InvestmentchartData = {
    options: {
      chart: {
        id: "hodl-defi-chart",
        toolbar: {
          autoSelected: "pan",
          show: false,
        },
      },
      colors: ["#FCD434"],
      grid: {
        borderColor: "#555",
        clipMarkers: false,
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      zoom: {
        enabled: false,
      },
      xaxis: {
        categories:
          investmentchart && investmentchart.length > 0
            ? investmentchart.map((data) =>
                moment(data._id).format("DD-MM-YYYY")
              )
              : ['08/09/2023','09/09/2023','10/09/2023','12/09/2023'],
      },
      tooltip: {
        theme: "dark",
      },
    },
    fill: {
      gradient: {
        enabled: true,
        opacityFrom: 0.55,
        opacityTo: 0,
      },
    },
    series: [
      {
        name: "Total Investment",
        data:
          investmentchart && investmentchart.length > 0
            ? investmentchart.map((data) => data.total)
            : ['10','30','100','10'],
      },
    ],
    tooltip: {
      theme: "dark",
    },
  };
  const Claimpopup= ()=>{
    window.$('#claim_popup').modal('show');
    
  }
  const Closepopup= ()=>{ 
    window.$('#claim_popup').modal('hide');
    toastAlert("error", "Claim Rejected", "invalid");
  }
  const handleClaim = async () => {
    
    try {
      setregbtn(true);
      var WEB3 = await connection();
      if (WEB3 && WEB3.address != "") {
        var contractAddress = config.contractAddress;
        var web3 = WEB3.web3;
        var Contract = new web3.eth.Contract(Abi, contractAddress);

        var currAddr = WEB3.address;
        var getBalance = await web3.eth.getBalance(currAddr);
        var bal = getBalance / 10 ** 18;
        console.log(bal, "ballll");
        if (bal <= 0) {
          toastAlert("error", "Insufficient Gas fee", "invalid");
          setregbtn(false);
          return;
        }
        var estimategas1 = await Contract.methods
          .claimReward()
          .estimateGas({ from: currAddr });
        var gas1 = estimategas1 + 100000;
        gas1 = gas1 / 10 ** 8;
        console.log(gas1, "gas1");

        if (gas1 > bal) {
          toastAlert("error", "Insufficient Gas fee", "invalid");
          setregbtn(false);
          return;
        }
        var claimreward = await Contract.methods
          .claimReward()
          .send({ from: currAddr });
          console.log(claimreward,'claimrewardclaimreward')
        if ( claimreward && claimreward.events && claimreward.events.claimEvent  && claimreward.status == true) {
          console.log(claimreward,'claimreward')
          setclaimhash(claimreward.transactionHash)
          setregbtn(false);

          var pool =  parseFloat(claimreward.events.Approval.claimEvent._pool)/1e18;
          var team =  parseFloat(claimreward.events.Approval.claimEvent._team)/1e18;
          var daily =  parseFloat(claimreward.events.Approval.claimEvent._daily)/1e18;
          var savevalue = pool + team+daily;
          console.log(savevalue,'savetrans315555')
          var savetrans= {
            address:currAddr,
            amount: parseFloat(savevalue),
            hash:claimreward.transactionHash,
            type:'withdraw'
          }
          console.log(savetrans,'savetrans315')
          await saveTransaction(savetrans)
          toastAlert("success", "Claim Reward success", "success");
          window.$('#claim_popup').modal('hide');
          window.$('#claim_success').modal('show');
           multicall()
          //  setTimeout(function () { 
          //   window.location.reload()
          // },500)
        }
      } else {
        setregbtn(false);
        window.$('#claim_popup').modal('hide');
        toastAlert("error", "Please connect your wallet", "invalid");
      }
    } catch (err) {
      setregbtn(false);
      console.log(err, "ererey");
      window.$('#claim_popup').modal('hide');
      toastAlert("error", "Claim Failed", "invalid");
    }
  };
  const handleRejoin = async (planid,planamount,packageprice) => {
    try{
      console.log(packageprice,'packagepricepackageprice')
setcurrplan(planid)
setrejoinbtn(true)
var WEB3 = await connection();
if (WEB3 && WEB3.address != "") {
  var contractAddress = config.contractAddress;
  var web3 = WEB3.web3;
  var Contract = new web3.eth.Contract(Abi, contractAddress);

  var currAddr = WEB3.address;


  let tokenContract = new web3.eth.Contract(TokenAbi, config.tokenAddress);
  let tokenBalance = await tokenContract.methods.balanceOf(currAddr).call();
  

  const tokenDecimal = await tokenContract.methods.decimals().call();

  var allowablebalance = tokenBalance / 10 ** parseFloat(tokenDecimal);
  console.log(allowablebalance, "allowablebalancewwewww");

  if (allowablebalance < planamount) {
    console.log(allowablebalance, "allowablebalancewwewwwiffffffff");

    toastAlert(
      "error",
      "Failed to Join due to insufficient USDT balance",
      "invalid"
    );
    setrejoinbtn(false);
    return;
  }




  var getBalance = await web3.eth.getBalance(currAddr);
  var bal = getBalance / 10 ** 18;
  console.log(bal, "ballll");
  if (bal <= 0) {
    toastAlert("error", "Insufficient Gas fee", "invalid");
    setrejoinbtn(false);
    return;
  }
         
          var allowance = await tokenContract.methods
          .allowance(currAddr, contractAddress)
          .call();
        console.log(allowance, "allowance");

        var allowamt = allowance / 1000000000000000000;

        
          if (allowablebalance < planamount) {
            var approveAmt = 100000 * 10 ** 18;
            var amt = await convert(approveAmt);
              var estimategas = await tokenContract.methods.approve(contractAddress, amt.toString()).estimateGas({ from: currAddr });
                var gas = estimategas + 100000;
                gas = gas / 10 ** 8
                console.log(estimategas, "estimategas");
                if (gas > bal) {
                  toastAlert("error", "Insufficient Gas fee", "invalid");
                  setrejoinbtn(false);
                  return;
                }

            var result1 = await tokenContract.methods
              .approve(contractAddress, amt.toString())
              .send({ from: currAddr });
            console.log(result1, "result1");

            if (
              result1 &&
              result1.events &&
              result1.events.Approval &&
              result1.events.Approval.returnValues &&
              result1.events.Approval.returnValues.value
            ) {
              var checkamt =
                result1.events.Approval.returnValues.value /
                1000000000000000000;
              if (checkamt < planamount) {
                toastAlert("error", "Approve amount is too low", "invalid");
                setrejoinbtn(false);
                return;
              }
            }
          }
  var estimategas2 = await Contract.methods
    .BuyPlan(planid.toString())
    .estimateGas({ from: currAddr });
  var gas2 = estimategas2 + 100000;
  gas2 = gas2 / 10 ** 8;
  console.log(gas2, "gas2gas2");

  if (gas2 > bal) {
    toastAlert("error", "Insufficient Gas fee", "invalid");
    setrejoinbtn(false);
    return;
  }
  var rejoinplan = await Contract.methods.BuyPlan(planid.toString()).send({ from: currAddr });
  
  if (rejoinplan.status == true) {
    
    var savetrans= {
      address:currAddr,
      amount:packageprice,
      hash:rejoinplan.transactionHash,
      type:'staking',
      status:'rejoin'
    }
    await saveTransaction(savetrans)
    setrejoinbtn(false);
    toastAlert("success", "Buy package success", "success");
    window.$('#rejoin_popup').modal('hide');
    setTimeout(function () {
      window.location.reload(false)
    }, 500);
    multicall()
  }
}
else{
  setrejoinbtn(false);
 
  toastAlert("error", "Please connect your wallet", "invalid");
}
    }
    catch(err){
      console.log(err,'error291')
    
      setrejoinbtn(false);
      toastAlert("error", "Rejoin Failed", "invalid");
    }
  }

  
 
  async function onCopy() {
    setcopytext(true);
    setTimeout(function () {
      setcopytext(false);
    }, 1000);
  }
  async function onCopy1() {
    setcopytextone(true);
    setTimeout(function () {
      setcopytextone(false);
    }, 1000);
  }
  async function getreferalusers(refaddress) {
    console.log(refaddress,'refaddress4555')
    try {
      var web3 = new Web3(config.netWorkUrl);
      var contractAddress = config.contractAddress;
      var Contract = new web3.eth.Contract(Abi, contractAddress);
      var getreferalusers = await Contract.methods
      .viewUserReferral(refaddress)
      .call();
      console.log(getreferalusers,'getreferalusers')
      var refarr=[];
      var childarr=[]
      var childofarr=[]
      var saveobj={};
      var childobj={};
      var childofobj={}
      for (var i in getreferalusers){
        var getreferalchild = await Contract.methods
        .viewUserReferral(getreferalusers[i])
        .call();
        console.log(getreferalchild,'getreferalchild')
        for(var j in getreferalchild){


          var getreferalchildofchild = await Contract.methods
          .viewUserReferral(getreferalchild[j])
          .call();
for(var k in getreferalchildofchild){
  console.log(getreferalchildofchild,'getreferalchildofchild')
  childofobj={
    key:getreferalchild[j],
    id:getreferalchildofchild[k],
    list3:[]
  }
  childofarr.push(childofobj)
console.log(childofobj,'childofobjchildofobj')

var childofuser=childofarr.find((item,val)=> {return item.key==getreferalchild[j]})
console.log(childofuser,'childofuser',getreferalchild[j])
}
           childobj={
            key:getreferalusers[i],
            id:getreferalchild[j],
            list2:childofuser?[childofuser]:[]
          }
          childarr.push(childobj)
       
        }
        var childuser=childarr.find((item,val)=> {return item.key==getreferalusers[i]})
        console.log(childuser,'childchilduserchilduserarrchildarrchildarrchildarr')
        saveobj={
          key:i,
          id:getreferalusers[i],
          list1:childuser?[childuser]:[]
        }
        
         refarr.push(saveobj)
        console.log(refarr,'refarrrefarrrefarrrefarr')
      }
      settreeList([{'id':refaddress,'list':refarr}])
      console.log(refarr,'refarr')
    } catch (error) {
      console.log(error,'eeeeeeeeeee22222222222')


    }

  }
  const handleAddress = (e) => {
    console.log(e.target.value, "vlll");
    setrefferaladdress(e.target.value);
    if(e.target.value==''){
     getreferalusers(walletConnection.address)
    }
  };
  const searchReferral = async () => {
    try{
      var chkaddr = Web3.utils.isAddress(refferaladdress);
      if (isEmpty(refferaladdress)) {
        setshowerror({ referalAddress: "Address is required" });
   
        return;
      }
      else if(!chkaddr) {
       setshowerror({ referalAddress: "Please enter valid address" })
      
        return;
      }
      else{
        setshowerror({ referalAddress: "" });
      }
      await getreferalusers(refferaladdress)
    }
    catch(err){

    }
  }
  return (
    <div>
      <Navbar />
      <div className="dashboard">
        <div className="dashboard_box">
          <div className="container">
           
            <div className="head_flx">
              <h2 className="h2tag mb-0">Dashboard</h2>
              {loader && <p><i class="fas fa-spinner fa-spin"></i>Loading....</p>}
              <div className="wallet">
                <span>
                  {" "}
                  Your Wallet :
                  <span>
                    {" "}
                    {walletConnection && walletConnection.address
                      ? walletConnection.address.slice(0, 7) +
                        "...." +
                        walletConnection.address.slice(10, 17)
                      : ""}
                  </span>
                </span>
                <CopyToClipboard
                  text={walletConnection.address}
                  onCopy={() => onCopy1()}
                >
                 
                    {!copytextone ? (
                    <img
                    src={require("../assets/images/copy.png")}
                    alt="copy"
                    className="img-fluid ms-3"
                  />
                  ) : (
                    <img
                      src={require("../assets/images/check.png")}
                      className="img-fluid"
                      alt="img"
                      width="20px"
                      heihgt="22px"
                    />
                  )}
                </CopyToClipboard>
              </div>
            </div>
            <div className="row mb-3 pb-2">
              <div className="col-lg-4 col-xxl-3">
                <div className="box">
                  <div>
                    <h5>
                      {userdata && userdata.userid ? userdata.userid : "0"}
                    </h5>
                    <p>My Present ID</p>
                  </div>
                  <div className="icon">
                    <img src={user} alt="img" className="img-fluid" />
                  </div>
                </div>
                <div className="box">
                  <div>
                    <h5>
                      {userdata && userdata.childcount
                        ? userdata.childcount
                        : "0"}
                    </h5>
                    <p>Direct Referral</p>
                  </div>
                  <div className="icon">
                    <img src={refferal} alt="img" className="img-fluid" />
                  </div>
                </div>
                <div className="box">
                  <div>
                    <h5>
                      {userdata && userdata.teamCount
                        ? userdata.teamCount
                        : "0"}
                    </h5>
                    <p>Team Count</p>
                  </div>
                  <div className="icon">
                    <img src={refferal} alt="img" className="img-fluid" />
                  </div>
                </div>
                <div className="box">
                  <div>
                    <h5>
                      {" "}
                      <img src={require("../assets/images/favicon.png")} width="26px" height="24px" className="img-fluid me-2"/>
                      {userdata && userdata.teamInvestment
                        ? userdata.teamInvestment
                        : "0"}{" "}
                    </h5>
                    <p> Team Investment Amount </p>
                  </div>
                  <div className="icon">
                    <img src={investment} alt="img" className="img-fluid" />
                  </div>
                </div>
                <div className="box">
                  <div>
                    <h5>{userdata && userdata.rank
                        ? userdata.rank
                        : "0"}</h5>
                    <p> Current Ranking Position </p>
                  </div>
                  <div className="icon">
                    <img src={position} alt="img" className="img-fluid" />
                  </div>
                </div>
                <div className="box align-items-start">
                  <div>
                    <p className="sm">
                      <span className="ylw">Daily</span> Income
                    </p>
                    <p>Pool Income</p>
                    <h5 className="mb-3"><img src={require("../assets/images/favicon.png")} width="26px" height="24px" className="img-fluid me-2"/>{userdata && userdata.poolincome
                        ? parseFloat(userdata.poolincome)
                        : "0"}</h5>
                    <p> Rank Reward</p>
                    <h5 className="mb-3"> <img src={require("../assets/images/favicon.png")} width="26px" height="24px" className="img-fluid me-2"/> {userdata && userdata.roiIncome
                        ?  parseFloat(userdata.roiIncome)
                        : "0"}</h5>
                       <p> ROI Income </p>
                    <h5> <img src={require("../assets/images/favicon.png")} width="26px" height="24px" className="img-fluid me-2"/> {userdata && userdata.totaldailyroiincome
                        ?  parseFloat(userdata.totaldailyroiincome)
                        : "0"}</h5>  
                  </div>
                  <div className="icon">
                    <img src={income} alt="img" className="img-fluid" />
                  </div>
                </div>

                <div className="box mb-0">
                  <div className="w-100">
                    <p className="sm mb-2">
                      <span className="ylw">Affiliate </span>Link
                    </p>
                    <div className="link">
                      <div className="half">
                        {`${config.frontUrl}join-now?referral=` +
                          walletConnection.address}
                      </div>
                      <div className="ms-3">
                      <CopyToClipboard
                          text={
                            `${config.frontUrl}join-now?referral=` +
                            walletConnection.address
                          }
                          onCopy={() => onCopy()}
                        >
                             {!copytext ? (
                    <img
                    src={require("../assets/images/copy.png")}
                    alt="copy"
                    className="img-fluid copy"
                  />
                  ) : (
                    <img
                      src={require("../assets/images/check.png")}
                      className="img-fluid"
                      alt="img"
                      width="20px"
                      heihgt="22px"
                    />
                  )}
                        
                        </CopyToClipboard>
                      </div>
                    </div>
                    <ul style={{"display":"flex","justify-content": "space-around"}}>
                      <li>
                        {/* <a href="#" target="_blank">
                          <i class="fa-brands fa-facebook-f"></i>
                        </a> */}
                        <FacebookShareButton  url={`${config.frontUrl}join-now?referral=` +
                          walletConnection.address} >
                              <FacebookIcon size={30} round={true} />
                              </FacebookShareButton>
                      </li>
                      <li>
                        {/* <a href="#" target="_blank">
                          <i class="fa-brands fa-instagram"></i>
                        </a> */}
                        <TwitterShareButton  url={`${config.frontUrl}join-now?referral=` +
                          walletConnection.address} >
                              <TwitterIcon size={30} round={true} />
                              </TwitterShareButton>
                      </li>
                      <li>
                        {/* <a href="#" target="_blank">
                          <i class="fa-brands fa-x-twitter"></i>
                        </a> */}
                         <TelegramShareButton  url={`${config.frontUrl}join-now?referral=` +
                          walletConnection.address} >
                              <TelegramIcon size={30} round={true} />
                              </TelegramShareButton>
                      </li>
                      <li>
                        {/* <a href="#" target="_blank">
                          <i class="fab fa-telegram-plane"></i>
                        </a> */}
                        <WhatsappShareButton  url={`${config.frontUrl}join-now?referral=` +
                          walletConnection.address} >
                              <WhatsappIcon size={30} round={true} />
                              </WhatsappShareButton>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-xxl-9">
                <div className="right">
                  <div className="row">
                    <div className="col-xl-4 d-flex">
                      <div className="box">
                        <div>
                          <p className="mb-2"> Earned Income</p>
                          {console.log(userdata,'oooooooooo')}
                          <h5>
                            {/* ${" "} */}
                            <img src={require("../assets/images/favicon.png")} width="26px" height="24px" className="img-fluid me-2"/>{userdata &&  userdata.earnedIncome
                              ? parseFloat(userdata.earnedIncome)
                              : "0"}
                          </h5>
                        </div>
                        {userdata  && (parseFloat(userdata.earnedIncome))==0?
                       <div className="button disable" >
                     
                       Claim
                     </div> :<div className="button " onClick={() => Claimpopup()}>
                        {regbtn && <i class="fas fa-spinner fa-spin"></i>}{" "}
                        Claim
                      </div>}
                        
                      </div>
                    </div>
                    {/* <div className="col-xl-4 d-flex">
                      <div className="box">
                        <div>
                          <p className="mb-2"> Next Claim Date & Time </p>
                          <h5>{(userdata && userdata.lastClaimed)?userdata.lastClaimed:"-"} </h5>
                        </div>
                      </div>
                    </div> */}
                          <div className="col-xl-4 d-flex">
                      <div className="box">
                        <div>
                          <p className="mb-2">Parent ID </p>
                          <h5>{(userdata && userdata.parentid)?userdata.parentid:"-"} </h5>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-4 d-flex">
                      <div className="box">
                        <div>
                          <p className="mb-2"> Total Investment Amount </p>
                          <h5> <img src={require("../assets/images/favicon.png")} width="26px" height="24px" className="img-fluid me-2"/>
                      {userdata && userdata.investAmount
                        ? userdata.investAmount
                        : "0"}{" "}</h5>
                        </div>
                        <div
                          className="button"
                          data-bs-toggle="modal"
                          data-bs-target="#rejoin_popup"
                        >
                          Buy
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 chart-width">
                      {/* <img src={require("../assets/images/investment_chart.png")} alt="img" className="img-fluid mb-3 w-100 pb-1" /> */}
                      <span>Total Investment</span>
                      <Chart
                        options={InvestmentchartData.options}
                        series={InvestmentchartData.series}
                        type="area" width="100%"
                        height={294}
                      />
                    </div>
                    <div className="col-md-6  chart-width">
                      <span>Total Users</span>
                      <Chart
                        options={chartData.options}
                        series={chartData.series}
                        type="area" width="100%"
                        height={294}
                      />
                      {/* <img src={require("../assets/images/user_chart.png")} className="img-fluid mb-3 w-100 pb-1" alt="img"/>  */}
                    </div>
                  </div>
                  <div className="table_box">
                    <h4> History</h4>
                    <ul class="nav nav-tabs" id="myTab" role="tablist">
                      <li class="nav-item" role="presentation">
                        <button
                          class="nav-link active"
                          id="one-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#one"
                          type="button"
                          role="tab"
                          aria-controls="one"
                          aria-selected="true"
                        >
                          Withdrawal
                        </button>
                      </li>
                      <li class="nav-item" role="presentation">
                        <button
                          class="nav-link"
                          id="two-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#two"
                          type="button"
                          role="tab"
                          aria-controls="two"
                          aria-selected="false"
                        >
                          Staking
                        </button>
                      </li>
                    </ul>
                    <div class="tab-content" id="myTabContent">
                      <div
                        class="tab-pane fade show active"
                        id="one"
                        role="tabpanel"
                        aria-labelledby="one-tab"
                      >
                        <Withdraw />
                      </div>
                      <div
                        class="tab-pane fade"
                        id="two"
                        role="tabpanel"
                        aria-labelledby="two-tab"
                      >
                        <Staking />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="bdr">
              <div className="row align-items-center">
                <div className="col-lg-6">
                  <h4>
                    <span className="ylw">Staking</span> Tree
                  </h4>
                </div>
                <div className="col-lg-6">
                  <div className="m-0">
                    <div class="input-group w-50 ms-auto">
                      <input
                        type="text"
                        class="form-control pe-1"
                        placeholder="Search ID"
                        style={{ color: "white" }}
                        onChange={handleAddress}
                      />
                    
                      <span class="input-group-text" id="basic-addon2"  onClick={() => searchReferral()}>
                        <img src={search} className="img-fluid" alt="Icon" />
                      </span>
                     
                    </div>
                    {showerror && showerror.referalAddress != "" && (
                    <p style={{ color: "red", fontSize: "17px",marginLeft:"270px"}}>
                      {showerror.referalAddress}
                    </p>
                  )}
                  </div>
                </div>
              </div>

              <div className="tree_flx">
                <div className="tree ps-2">
                  {console.log(treeList,'treeListtreeList')}
                  
                {treeList && treeList.length>0 && treeList.map((items,key)=>{
                  {console.log(items,'itemsitemsitems')}
                  return(
                  <CustomizedIconTree open
                  key={key}
                  content={<span className="id">ID {items.id}</span>}  type={<i class="fas fa-user"></i>}
               >
                 {items && items.list.length>0 && items.list.map((items1,key1)=>{
                   console.log(items1,'items1items1')
                   return(
                  <CustomizedIconTree key={key1}  content={<span className="id">ID {items1.id}</span>}  type={<i class="fas fa-user"></i>}>
                  
                  {items1 && items1.list1.length>0 && items1.list1.map((items2,key2)=>{
                   console.log(items2,'items2items2items2')
                   return(
                  <CustomizedIconTree key={key2}  content={<span className="id">ID {items2.id}</span>}  type={<i class="fas fa-user"></i>}>
                  
                  {items2 && items2.list2.length>0 && items2.list2.map((items3,key3)=>{
                   console.log(items2,'items2items2items2')
                   return(
                  <CustomizedIconTree key={key3}  content={<span className="id">ID {items3.id}</span>}  type={<i class="fas fa-user"></i>}>
                  
                  
                   </CustomizedIconTree>
                   )
                   })
                   
                  }

                   </CustomizedIconTree>
                   )
                   })
                   
                  }

                   </CustomizedIconTree>
                   )
                   })
                   
                  }
  
                 </CustomizedIconTree>)

})
}
                 




                </div>
                <div className="box">
                  <i class="fas fa-user"></i> ID {userdata && userdata.userid ? userdata.userid : "0"}
                  <div className="mt-2">
                    <p>Investment Amount :</p>
                    <span>{userdata && userdata.investAmount
                        ? userdata.investAmount
                        : "0"}</span>
                  </div>
                  {/* <div>
                    <p>Earned Amount :</p>
                    <span>100</span>
                  </div> */}
                  <div>
                    <p>Direct refferal :</p>
                    <span>{userdata && userdata.childcount
                        ? userdata.childcount
                        : "0"}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />

      {/* rejoin Modal */}
      <div
        className="modal fade primary_modal join_modal"
        id="rejoin_popup"
        tabIndex={-1}
        aria-labelledby="connect_walletModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog   modal-dialog-centered modal-lg justify-content-center">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="connect_walletModalLabel">
                Packages
              </h5>
              
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
             
            </div>
            {rejoinbtn && <span className="text-center my-2" >Note:Do not refresh the page,when transaction is processing</span>}
            <div className="modal-body">
              <div className="joinnow">
                <div className="row mt-3">
                  <div className="col-lg-12 mx-auto">
                    {list1 &&
                      list1.length > 0 &&
                      list1.map((item, key) => (
                        <>
                          <div className="whole_box">
                            <div className="box p-2">
                              <div>
                                <div className="num">{item.packagenum}</div>
                                <p>package</p>
                              </div>
                              <div>
                                <h5>{item.packageprice}</h5>
                                <span>TBX</span>
                              </div>
                              <div>
                                <h5>{item.usdtprice}</h5>
                                <span>USDT</span>
                              </div>
                              <button className={`primary_btn`} onClick={() => handleRejoin(item.packagenum,item.usdtprice,item.packageprice)} disabled={rejoinbtn}>
                              {item.packagenum==currplan && rejoinbtn && <i class="fas fa-spinner fa-spin"></i>}{" "}Buy
                              </button>
                            </div>
                          </div>
                        </>
                      ))}

                    {/* <div className="whole_box">
                <div className="box p-2">
                   <div>
                      <div className="num">2</div>
                      <p>package</p>
                   </div>
                   <div>
                     <h5>500</h5>
                     <span>TBX</span>
                   </div>
                   <div>
                     <h5>125</h5>
                     <span>USDT</span>
                   </div>
                   <button className={`primary_btn`} >join now</button>
                </div>
            </div>
            <div className="whole_box">
                <div className="box p-2">
                   <div>
                      <div className="num">3</div>
                      <p>package</p>
                   </div>
                   <div>
                     <h5>1000</h5>
                     <span>TBX</span>
                   </div>
                   <div>
                     <h5>250</h5>
                     <span>USDT</span>
                   </div>
                   <button className={`primary_btn`} >join now</button>
                </div>
             </div>
             <div className="whole_box">
                <div className="box p-2">
                   <div>
                      <div className="num">4</div>
                      <p>package</p>
                   </div>
                   <div>
                     <h5>2000</h5>
                     <span>TBX</span>
                   </div>
                   <div>
                     <h5>500</h5>
                     <span>USDT</span>
                   </div>
                   <button className={`primary_btn`} >join now</button>
                </div>
            </div>
            <div className="whole_box">
                <div className="box p-2">
                   <div>
                      <div className="num">5</div>
                      <p>package</p>
                   </div>
                   <div>
                     <h5>5000</h5>
                     <span>TBX</span>
                   </div>
                   <div>
                     <h5>1250</h5>
                     <span>USDT</span>
                   </div>
                   <button className={`primary_btn`} >join now</button>
                </div>
             </div>
             <div className="whole_box">
                <div className="box p-2">
                   <div>
                      <div className="num">6</div>
                      <p>package</p>
                   </div>
                   <div>
                     <h5>10000</h5>
                     <span>TBX</span>
                   </div>
                   <div>
                     <h5>2500</h5>
                     <span>USDT</span>
                   </div>
                   <button className={`primary_btn`} >join now</button>
                </div>
            </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      


      <div
        className="modal fade primary_modal join_modal"
        id="claim_popup"
        tabIndex={-1}
        aria-labelledby="connect_walletModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog   modal-dialog-centered modal-lg justify-content-center">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="connect_walletModalLabel">
                Are you sure to claim
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div>
                <div className="row mt-3">
                  <div className="col-lg-12 mx-auto">
                    <div  className="d-flex align-items-center justify-content-center py-4" >
                  <button className={`primary_btn me-4`}  onClick={() => handleClaim()}>
                          {regbtn && <i class="fas fa-spinner fa-spin"></i>}{" "}
                          Yes
                        </button>
                        <button className={`primary_btn ms-4`} onClick={() => Closepopup()}>
                        
                          No
                        </button>
                        </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade primary_modal join_modal"
        id="claim_success"
        tabIndex={-1}
        aria-labelledby="connect_walletModalLabel"
        aria-hidden="true"
        data-bs-backdrop="static"
      >
        <div className="modal-dialog   modal-dialog-centered modal-lg justify-content-center">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="connect_walletModalLabel">
               Transaction Success
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div>
              <div className="mb-4">
                                    <svg className="svg ticmark" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                        <circle className="path circle" fill="none" stroke="#73AF55" strokeWidth="6" strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                                        <polyline className="path check" fill="none" stroke="#73AF55" strokeWidth="6" strokeLinecap="round" strokeMiterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                                    </svg>
                                    <a rel="noreferrer" href={config.txUrl+claimhash} target="_blank" className="d-block mt-3 text-center"> <i className="fa fa-eye" aria-hidden="true">
                                    </i> View Transaction </a>

                                </div>
                {/* <div className="row mt-3">
                  <div className="col-lg-12 mx-auto">

                    
                    <a href={config.txUrl+claimhash}>View Transaction</a>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
