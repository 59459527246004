import { combineReducers } from "redux";


import walletConnection from './walletConnection';


export default combineReducers({

    walletConnection,

});