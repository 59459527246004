import React, { useEffect, useState } from "react";
import Footer from "../components/Footer.js";
import Navbar from "../components/Navbar.js";  
import { Link } from "react-router-dom"; 
import Web3 from 'web3';
import config from '../config/config'
import Abi from '../ABI/Abi.json'
import TokenAbi from '../ABI/TokenAbi.json'
import { convert } from "../helper/convert";
import { isEmpty } from "../helper/isEmpty";
import { connection } from "../helper/connection";
import { toastAlert } from "../helper/toastAlert";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useSelector } from "react-redux";
import {saveUsers,saveTransaction} from "../API/UserActions"
import { useNavigate } from 'react-router-dom';
import {formatNumber ,toFixedWithoutRound} from '../helper/custommath';

import { bignumber, multiply, divide, add } from 'mathjs'



export default function Joinnow () {
  const location = useLocation();
  const walletConnection = useSelector((state) => state.walletConnection);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(1);
  const [list1, setlist1] = useState([])
  const [tbxPrice, settbxPrice] = useState('')
  const [refferal, setrefferal] = useState("");
  const [showerror, setshowerror] = useState({});
  const [regbtn, setregbtn] = useState(false);
  const [refstatus, setrefstatus] = useState(false);
  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex === activeTab ? null : tabIndex);
    setshowerror({ referalAddress: "" });
  };
  async function getpackage() {
    try{
                    var web3 =  new Web3(config.netWorkUrl);
                    var contractAddress = config.contractAddress
                    var Contract = new web3.eth.Contract(Abi,contractAddress);
                    var tbxprice =  await Contract.methods.tokenPrice().call()
                    console.log(tbxprice,'tbxprice')
                    tbxprice=tbxprice/1e6
                    settbxPrice(tbxprice)
                    var arr1=[];var arr2=[]
                    for(var i =1;i<=6;i++){
                      var obj1={}
                      var data=await Contract.methods.PACKAGE_PRICE(i).call();
                      var pkgprice = bignumber(data);
                      var decimal = bignumber(1e18);
                      var price = divide(pkgprice, decimal);
                      price = price.toString();

                      var tbxrate = bignumber(tbxprice);
                      var tokenVal = divide(price, tbxrate);
                      tokenVal =tokenVal.toString()

                      console.log(data,'data47')
                      obj1.packagenum=i;
                      obj1.packageprice = await formatNumber (price,4);
                      obj1.usdtprice = await formatNumber(tokenVal,4);
                      arr1.push(obj1)
                    }
                    setlist1(arr1)
                    console.log(arr1,'arr123112')
                   
    }
    catch(err){
    console.log(err,'eeeeeeee')
    }
          }

          const handleAddress = (e) => {
            console.log(e.target.value, "vlll");
            setrefferal(e.target.value);
          };
          const handleSubmit = async (planid,planamount,packageprice) => {
            try {
            
console.log(packageprice,'planid,planamount')

setregbtn(true);
var chkaddr = Web3.utils.isAddress(refferal);
if (isEmpty(refferal)) {
  setshowerror({ referalAddress: "Referral Id is required" });
  setregbtn(false);
  return;
}
else if(!chkaddr) {
 setshowerror({ referalAddress: "Please enter valid address" })
 setregbtn(false);
  return;
}
else{
  setshowerror({ referalAddress: "" });
}
var WEB3 = await connection();
if (WEB3 && WEB3.address != "") {
  var contractAddress = config.contractAddress;
        var web3 = WEB3.web3;
        var Contract = new web3.eth.Contract(Abi, contractAddress);

        var currAddr = WEB3.address;
        var refcheck = await Contract.methods.users(refferal).call();
        if (refcheck  && refcheck.isExist == false) {
          setregbtn(false);
          toastAlert("error", "Invalid Referral Address", "invalid");
          setrefferal("");
          return;
        }
        var usercheck = await Contract.methods.users(currAddr).call();
        console.log(usercheck.isExist, "usercheck");
        if (usercheck && usercheck.isExist && usercheck.isExist == true) {
          setregbtn(false);
          toastAlert("error", "Already registered", "invalid");
          setrefferal("");
          return;
        }
        var tokenAddress = config.tokenAddress;
        let tokenContract = new web3.eth.Contract(TokenAbi, tokenAddress);
        console.log(tokenContract, "tokenContract");
        var allowance = await tokenContract.methods
          .allowance(currAddr, contractAddress)
          .call();
        console.log(allowance, "allowance");

        var allowamt = allowance / 1000000000000000000;

        var approveAmt = 100000 * 10 ** 18;
        var amt = await convert(approveAmt);
        console.log(allowamt, "amtttttt");

        let contract = new web3.eth.Contract(TokenAbi, config.tokenAddress);
        let tokenBalance = await contract.methods.balanceOf(currAddr).call();

        const tokenDecimal = await contract.methods.decimals().call();

        var allowablebalance = tokenBalance / 10 ** parseFloat(tokenDecimal);
        console.log(allowablebalance, "allowablebalancewwewww");
console.log(planamount,'planamountplanamount')
        if (allowablebalance < planamount) {
          console.log(allowablebalance, "allowablebalancewwewwwiffffffff");

          toastAlert(
            "error",
            "Failed to Join due to insufficient USDT balance",
            "invalid"
          );
          setregbtn(false);
          return;
        }




        var getBalance = await web3.eth.getBalance(currAddr);
          var bal = getBalance / 10 ** 18;
          console.log(bal, "ballll");
          if (bal <= 0) {
            toastAlert("error", "Insufficient Gas fee", "invalid");
            setregbtn(false);
            return;
          }
          
          var estimategas = await tokenContract.methods.approve(contractAddress, amt.toString()).estimateGas({ from: currAddr });
            var gas = estimategas + 100000;
            gas = gas / 10 ** 8
            console.log(estimategas, "estimategas");
            if (gas > bal) {
              toastAlert("error", "Insufficient Gas fee", "invalid");
              setregbtn(false);
              return;
            }
          if (allowamt< planamount) {
            var result1 = await tokenContract.methods
              .approve(contractAddress, amt.toString())
              .send({ from: currAddr });
            console.log(result1, "result1");

            if (
              result1 &&
              result1.events &&
              result1.events.Approval &&
              result1.events.Approval.returnValues &&
              result1.events.Approval.returnValues.value
            ) {
              var checkamt =
                result1.events.Approval.returnValues.value /
                1000000000000000000;
              if (checkamt < planamount) {
                toastAlert("error", "Approve amount is too low", "invalid");
                setregbtn(false);
                return;
              }
            }
          }console.log(refferal,planamount,planid,'refferal,planamount,planid')
          var passamnt = planamount*10 ** 18
          var estimategas1 = await Contract.methods
              .regUser(refferal,planid)
              .estimateGas({ from: currAddr });
            var gas1 = estimategas1 + 100000;
            gas1 = gas1 / 10 ** 8;

           
            if (gas1 > bal) {
              toastAlert("error", "Insufficient Gas fee", "invalid");
              setregbtn(false);
              return;
            }
            var passamnt = planamount*10 ** 18
            var joinplan = await Contract.methods.regUser(refferal,planid).send({ from: currAddr });

          console.log(joinplan, "joinplan");
          if (joinplan.status == true) {
            setregbtn(false);
            var savedata={
              address:currAddr
            }
             saveUsers(savedata)
            var savetrans= {
              address:currAddr,
              amount:packageprice,
              hash:joinplan.transactionHash,
              type:'staking',
              status:'buy'
            }
            saveTransaction(savetrans)
            toastAlert("success", "Register success", "success");
            setrefferal("");
            setTimeout(function () {
              window.location.href='/dashboard'
              
            }, 500);
          }
}
else{
  setregbtn(false);
  toastAlert("error", "Please connect your wallet", "invalid");
}
            }
            catch(err){
              setregbtn(false);
              toastAlert("error", "Failed to Register", "invalid");
console.log(err,'err')
            }
          }


          useEffect(() => {
            getpackage()
          },[])

          useEffect(() => {
            const parsed = queryString.parse(location.search);
            console.log(parsed, "parsed");
            if (parsed && parsed.referral && parsed.referral != "") {
              setrefstatus(true);
              setrefferal(parsed.referral)
            }
          
          
          }, [walletConnection, walletConnection.address]);
  return (
    <div> 
      <Navbar />
      <div className="joinnow" >
      <div className="joinnow_bg py-5" >
      <div className="container">
       <div className="head mb-5">
         <h2 className="h2tag">Choose Your <br /><span> TBX STAKING</span> Packages</h2>
         <p className="para">We have set a rate of <span>{tbxPrice?tbxPrice:''} TBX </span> per <span> 1 USDT</span> for the TBX token during the pre-sale. If we want to change the pricing according to total supply then we can change it</p>
       </div>

       <div className="row">
         <div className="col-lg-8 mx-auto">
         {list1 && list1.length > 0 && list1.map((item,key) => (
                    <>
                    {console.log(key,'keyoooooooo')}
                          <div className="whole_box">
<div className="box">
   <div>
      <div className="num">{item.packagenum}</div>
      <p>package</p>
   </div>
   <div>
     <h5>{item.packageprice}</h5>
     <span>TBX</span>
   </div>
   <div>
     <h5>{item.usdtprice}</h5>
     <span>USDT</span>
   </div>
   <button className={`butn ${activeTab === item.packagenum? "active" : "" } `}  onClick={() => handleTabClick(item.packagenum)} >Subscribe</button>
</div>
{activeTab === item.packagenum &&
 <div className="blw_box" >
   <input type="text" className="form-control" onChange={handleAddress} value={refferal} placeholder="Enter your referral ID"  style={{ color: "white" }}/>

   <button className="primary_btn"  disabled={regbtn}  onClick={() => handleSubmit(item.packagenum,item.usdtprice,item.packageprice)}>{regbtn && <i class="fas fa-spinner fa-spin"></i>}Join Now</button>
   {regbtn && <span>Note:Do not refresh the page,when transaction is processing</span>}
   {showerror && showerror.referalAddress != "" && (
                    <p style={{ color: "red", fontSize: "17px" }}>
                      {showerror.referalAddress}
                    </p>
                  )}
 </div>
 }
</div>
                    </>
                  ))}
   
         </div>
       </div>
       </div>
       </div>
       </div>

      <Footer />
    </div>
  );
}
