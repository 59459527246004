import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getCMSById } from "../API/UserActions";
import Navbar from "../components/Navbar.js";
import config from "../config/config";
import moment from "moment";
import ReactHtmlParser from "react-html-parser";
import Footer from "../components/Footer.js";
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}
const Cms = () => {
  const Params = useParams();
  const [newsArtical, setNewsArtical] = useState({});
  console.log(Params,'2222222222222');

  useEffect(() => {
    getLoader();
  }, []);

  const getLoader = async () => {
    try {
      const payload = {
        id: Params.id,
      };
      const data = await getCMSById(payload);

      console.log(data);
      if (data.result) {
        setNewsArtical(data.result);
      } else {
        setNewsArtical([]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const convertHtml = (ele) => {
    return <div>{ReactHtmlParser(ele)}</div>;
  };
  return (
    <div>
      <ScrollToTopOnMount />
      <Navbar />
      {/* NewsArticle */}

      <div class="blog_header news_detail_header">
        <div class="container">
          <div class="row">
            <div class="col-lg-10 m-auto">
              <div className="blog_detail_sec">                
                
                <h2>{newsArtical.title}</h2>
                <h5>{newsArtical.description}</h5>
               
                <div class="blog_detail_content mt-3">
                  <div>{convertHtml(newsArtical.content)}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Cms;
