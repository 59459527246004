import axios from "axios";
import config from "../config/config";


const baseUrl = config.baseUrl;
export const getHandbook = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/get-handbook`,
            'data': data
        });
        return {
            message: respData.data.message,
            result: respData.data.result,
            success:respData.data.success
        }
    }
    catch (err) {
        return {
            errors: returnErr(err)
        }
    }
}
export const getPresaleDate = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/get-presale-date`,
            'data': data
        });
        return {
            message: respData.data.message,
            result: respData.data.result,
            success:respData.data.success
        }
    }
    catch (err) {
        return {
            errors: returnErr(err)
        }
    }
}
export const getTbxchart = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/get-tbx-chart`,
            'data': data
        });
        return {
            message: respData.data.message,
            result: respData.data.result,
            success:respData.data.success
        }
    }
    catch (err) {
        return {
            errors: returnErr(err)
        }
    }
}
export const saveUsers = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/save-users`,
            'data': data
        });
        return {
            message: respData.data.message,
            result: respData.data.result,
            success:respData.data.success
        }
    }
    catch (err) {
        return {
            errors: returnErr(err)
        }
    }
}
export const getUserchart = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/get-user-chart`,
            'data': data
        });
        return {
            message: respData.data.message,
            result: respData.data.result,
            success:respData.data.success
        }
    }
    catch (err) {
        return {
            errors: returnErr(err)
        }
    }
}
export const saveTransaction = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/save-trans`,
            'data': data
        });
        return {
            message: respData.data.message,
            result: respData.data.result,
            success:respData.data.success
        }
    }
    catch (err) {
        return {
            errors: returnErr(err)
        }
    }
}
export const getInvestmentchart = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/get-investment-chart`,
            'data': data
        });
        return {
            message: respData.data.message,
            result: respData.data.result,
            success:respData.data.success
        }
    }
    catch (err) {
        return {
            errors: returnErr(err)
        }
    }
}

export const getRecentTransaction = async (query) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/get-recent-trans`,
            params: query
        });
        console.log(respData,'respData147')
        return {
            message: respData.data.message,
            result: respData.data.result.data,
            count: respData.data.result.count,
            success:respData.data.success
        }
    }
    catch (err) {
        return {
            errors: returnErr(err)
        }
    }
}
export const savemail = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/save-email`,
            'data': data
        });
        return {
            message: respData.data.message,
            result: respData.data.result,
            success:respData.data.success
        }
    }
    catch (err) {
        return {
            errors: returnErr(err)
        }
    }
}
function returnErr(err) {
    if (err.response && err.response.data && err.response.data.errors) {
        console.log( err.response.data.errors,'ererererererereeeeeeeeeeeeeeee')
        return err.response.data.errors;
    }
    else {
        return '';
    }
}


export const getAllNewsUpdates = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${baseUrl}/getall_news`,
            
        });
        console.log(respData,'news');
        return {
            message: respData.data.message,
            result: respData.data.data,
            success:respData.data.success
        }
    }
    catch (err) {
        return {
            errors: returnErr(err)
        }
    }
}

export const getAllLaunchPadAction = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${baseUrl}/getall_launch`,      
        });
        console.log(respData,'sssswwewsssss');
        return {
            message: respData.data.message,
            result: respData.data.data,
            success:respData.data.success
        }
    }
    catch (err) {
        return {
            errors: returnErr(err)
        }
    }
}

export const getAllFaqAction = async () => {
    try {
        let respData = await axios({
            'method': 'get',
            'url': `${baseUrl}/getall_faq`,      
        });
        console.log(respData,'sssswwewsssss');
        return {
            message: respData.data.message,
            result: respData.data.data,
            success:respData.data.success
        }
    }
    catch (err) {
        return {
            errors: returnErr(err)
        }
    }
}


export const getNewsById = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/getNewsById`,
            data
            
        });
        console.log(respData,'news');
        return {
            message: respData.data.message,
            result: respData.data.data,
            success:respData.data.success
        }
    }
    catch (err) {
        return {
            errors: returnErr(err)
        }
    }
}

export const getContact = async () => {
    try {
        let respData = await axios({
            method: 'get',
            url: `${baseUrl}/get-contacts`
        })
        console.log(respData, 'contact')
        return {
            message: respData.data.message,
            success: respData.data.success,
            result: respData.data.result,
        }
    } catch (err) {
        return {
            errors: returnErr(err),
        }
    }
}
export const getCMSById = async (data) => {
    try {
        let respData = await axios({
            'method': 'post',
            'url': `${baseUrl}/get-cms-id`,
            data
            
        });
        console.log(respData,'getCMSByIdgetCMSById');
        return {
            message: respData.data.message,
            result: respData.data.data,
            success:respData.data.success
        }
    }
    catch (err) {
        return {
            errors: returnErr(err)
        }
    }
}