import React, { useState, useEffect } from "react";
import logo from "../assets/images/logo.svg";
import $ from 'jquery';
import { useSelector, useDispatch } from "react-redux";
import { setWallet } from "../reducers/Actions";
import { connection } from "../helper/connection";
import {getHandbook} from "../API/UserActions"
import fileDownload from 'js-file-download'
import Web3 from "web3";
import axios from "axios"
import {checkuser} from "../helper/connection"
import { useNavigate,Link} from 'react-router-dom';
import {
  type PublicClient,
  type WalletClient,
  useWalletClient,
  useAccount,
  useDisconnect,
  useNetwork,
  useSwitchNetwork,
} from "wagmi";

import {
  useEthersSigner,
  walletClientToSigner,
} from "../pages/ethersConnect.js";
import WalletModal from "./WalletModal";
import config from "../config/config";
import {toFixedFormat } from "../helper/custommath"
import { toastAlert } from "../helper/toastAlert";
import TokenAbi from '../ABI/TokenAbi.json'
import UsdtAbi from '../ABI/UsdtAbi.json'
export default function Navbar(props) {
  const navigate = useNavigate();
  const [balance, setbalance] = useState("");
  const [tokenbalance, settokenbalance] = useState("");
  const [usdtbalance, setusdtbalance] = useState("");
  const [handbookdata, sethandbookdata] = useState({"planhandbook":""});
  const [path, setpath] = useState("");
  const [pdfloader, setpdfloader] = useState(false);
  const [data, setData] = useState({});
  const dispatch = useDispatch();
  const walletConnection = useSelector((state) => state.walletConnection);
  //console.log(walletConnection,'walletConnection30')
  const { chain } = useNetwork();
  const { chains, error, isLoading, pendingChainId, switchNetwork } =
  useSwitchNetwork();
  let chainId = config.NetworkId;
  const { data: walletClient } = useWalletClient({ chainId });
  useAccount({
    onDisconnect: () => {
      localStorage.clear();
    window.location.href='/'
      console.log("Disconnected");
      dispatch(
        setWallet({
          network: walletConnection.network,
          web3: "",
          address: "",
          provider: "",
          connect: "no",
        })
      );
    },
  });

  useEffect(() => {
    if (chain && chain.id !== config.NetworkId) {
      dispatch(
        setWallet({
          network: "",
          web3: "",
          address: "",
          provider: "",
          connect: "",
          isChange: "true",
        })
      );
    } else {
      dispatch(
        setWallet({
          network: chainId,
          web3: walletConnection.web3,
          address: walletConnection.address,
          provider: walletConnection.provider,
          connect: "yes",
          isChange: "false",
        })
      );
    }
  }, [walletClient, chain?.network]);

  async function setConnection() {
    if (walletClient) {
      var get = await connection();

      console.log(get, "gettttttt");
      var { signer, transport } = walletClientToSigner(walletClient);

      var web3 = new Web3(transport);

      dispatch(
        setWallet({
          network: config.NetworkId,
          web3: web3,
          address: walletClient.account.address,
          provider: transport,
          connect: "yes",
          isChange: "false",
        })
      );
    }
  }

  useEffect(() => {
    
    setConnection();
  }, [walletConnection.network, walletClient]);


  const { disconnect,isSuccess,status } = useDisconnect()
  console.log(isSuccess,'isSuccessisSuccessisSuccess')
  if(isSuccess == true){
    setTimeout(function () {
              window.location.reload(false)
            }, 500);
  }
  async function getuserBalance() {
   
    if (chain && chain.id == config.NetworkId){

    
    if (walletConnection && walletConnection.web3 && walletConnection.address && walletConnection.address != "") {
    
      var web3 = walletConnection.web3;
      var getBalance = await web3.eth.getBalance(walletConnection.address);
      var bal = getBalance / 10 ** 18;
      bal = await toFixedFormat(bal);
      setbalance(bal);    
      let contract = new web3.eth.Contract(TokenAbi, config.tbxAddress);
      let tokenBalance = await contract.methods.balanceOf(walletConnection.address).call();

      const tokenDecimal = await contract.methods.decimals().call();
      var allowablebalance = tokenBalance / 10 ** parseFloat(tokenDecimal);
      settokenbalance(allowablebalance)

      let tokenContract = new web3.eth.Contract(UsdtAbi, config.tokenAddress);
      let usdttokenBalance = await tokenContract.methods.balanceOf(walletConnection.address).call();

      const usdttokenDecimal = await contract.methods.decimals().call();
      var usdtallowablebalance = usdttokenBalance / 10 ** parseFloat(usdttokenDecimal);
      console.log(usdtallowablebalance,'usdtallowablebalance')
      setusdtbalance(usdtallowablebalance)
    }
  }
  if(chain && chain.id != config.NetworkId){
    toastAlert("error", "Choose BSC Smart Chain Mainnet",'err') 
    setTimeout(function () {
      disconnect()
            }, 1000);
      

  }
  }

  async function getData() {
let{result,success}=await getHandbook()
if(success==true){
  sethandbookdata(result)
}
  }
  useEffect(() => {
    getuserBalance()
    
  },[walletConnection , walletConnection.network])
  useEffect(() => {
    check()
    
  },[walletConnection.address])
  useEffect(() => {
    getData()
  },[])
  async function check() {
   
    var checkdata = await checkuser();
    console.log(checkdata, "checkdata151",window.location.pathname);
    setData(checkdata)
    if (checkdata && !checkdata.exist && window.location.pathname=='/dashboard') {
      navigate('/join-now');
    }
    if (checkdata && checkdata.exist && window.location.pathname=='/join-now') {
      navigate('/dashboard');
    }
  
  }
  useEffect(() => {
   
    console.log(window.location.pathname,'hrefff')
    setpath(window.location.pathname)
    loadScript();
  }, []);

  function loadScript() {
    // Navbar Sticky
    var t = $(".navbar-sticky");
    $(window).on("scroll load", function (e) {
      var e = $(window).scrollTop();
      120 <= e
        ? t.addClass("navbar-sticky--moved-up")
        : t.removeClass("navbar-sticky--moved-up");
      250 <= e
        ? t.addClass("navbar-sticky--transitioned")
        : t.removeClass("navbar-sticky--transitioned");
      500 <= e
        ? t.addClass("navbar-sticky--on")
        : t.removeClass("navbar-sticky--on");
    });
  }
  const handleDownload = (url, filename) => {

    setpdfloader(true)
    console.log(url,'filenameeeeeee: ', filename);
    axios.get(url, {
      responseType: 'blob',
    })
      .then((res) => {
        fileDownload(res.data, filename)
        setpdfloader(false)
      })
  }
  return (
    <>
      <nav className="navbar navbar-dark navbar-expand-lg main_navbar navbar-sticky">
        <div className="container">
          <Link className="navbar-brand" to="/">
            <img
              src={logo}
              className="img-fluid brand_logo"
              alt="logo"
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="offcanvas offcanvas-end"
            tabIndex={-1}
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel"></h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <div className="ms-auto navbar_right">
                {/* After Login Menus */}
                {walletConnection &&
                walletConnection.connect === "yes" &&
                walletConnection.address &&
                walletConnection.address !== "" && (
                  <>

<div className="dropdown">
                  <a
                    className="dropdown-toggle address_btn"
                    href="#"
                    role="button"
                    id="dropdownMenuLink"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                     <img src={require("../assets/images/wallet.png")} className="img-fluid" alt="img"/> 
                     {walletConnection &&  walletConnection.address ? walletConnection.address.slice(0,7)+'....'+walletConnection.address.slice(10,17):''}
                  </a>
                  <ul
                    className="dropdown-menu walletdrop "
                    aria-labelledby="dropdownMenuLink"
                  >
                     <li>
                      <a className="dropdown-item" href="javascript:void(0)" style={{ "text-decoration": "none","cursor":"default"}}>
                        <label>BNB Balance:</label>
                        <span className="ms-2" >{ balance? balance:0} </span>
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="javascript:void(0)" style={{ "text-decoration": "none","cursor":"default"}}>
                        <label>TBX Balance:</label>
                        <span className="ms-2" >{tokenbalance?tokenbalance:0}</span>
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="javascript:void(0)" style={{ "text-decoration": "none","cursor":"default"}}>
                        <label>USDT Balance:</label>
                        <span className="ms-2" >{usdtbalance?usdtbalance:0}</span>
                      </a>
                    </li>
                    {path && data &&  path!='/dashboard' && data.exist==true &&
                     <li>
                      <div data-bs-dismiss="offcanvas" aria-label="Close">
                     <Link className="dropdown-item" to="/dashboard">
                       Dashboard
                     </Link>
                     </div>
                   </li>
                    }
                   
                  </ul>
                </div>
                  </>)}
                
                  <button
                  className="primary_btn me-2"     onClick={()=>handleDownload(config.serverURL+"handbook/"+handbookdata.planhandbook,handbookdata.planhandbook)}             
                >
                  {pdfloader && <i class="fas fa-spinner fa-spin"></i>}Download Whitepaper
                </button>
              
                {walletConnection &&
                walletConnection.connect === "yes" &&
                walletConnection.address &&
                walletConnection.address !== "" ? 
                <div  data-bs-dismiss="offcanvas" aria-label="Close"      >
                <button className="primary_btn ms-5"  onClick={() => disconnect()}>log out</button></div>:
                <div data-bs-dismiss="offcanvas" aria-label="Close"> <button
                className="primary_btn ms-5 me-2"       
                data-bs-toggle="modal"
                data-bs-target="#connect_wallet_modal"     
              >
                Connect Wallet
              </button>
              </div>
              }
              {path && data && (path!='/join-now'&&path!='/dashboard')&&(data.exist==false)
                &&   <div  data-bs-dismiss="offcanvas" aria-label="Close"> <Link className="primary_btn  ms-5" to='/join-now' >
                Join Now
              </Link></div>}
              </div>
            </div>
          </div>
        </div>
      </nav>

      {/* Connect Wallet Modal */}
    <WalletModal/>
    </>
  );
}
